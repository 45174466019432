import { style } from "typestyle"

import { Font, Color } from "../../var"

export const bannerStyle = style({
    width: '100%',
    marginTop: '105px',
    padding: '60px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'AvenirNext',
    $nest: {
        '& .cont': {
            width: 'calc(100% - 40px)',
            padding: '20px',
            maxWidth: '1024px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            $nest: {
                '& .imgDesktop':{
                    display: 'block'
                },
                '& .imgMobile':{
                    display: 'none'
                },
                '& .info': {
                    maxWidth: '971px',
                    marginRight: '70px',
                    $nest: {
                        '& .logo': {
                            width: '270px',
                            marginBottom: '70px',
                            padding: ' 0px 20px'
                        },
                        '& .title': {
                            fontSize: Font.MegaBig,
                            marginBottom: '50px',
                            fontWeight: 'bold',
                            $nest: {
                                '@media screen and (max-width: 930px)': {
                                    padding: '0px 20px'
                                },
                                '@media screen and (max-width: 500px)': {
                                    fontSize: Font.Grande
                                }
                            }
                        },
                        '& .text': {
                            maxWidth: '486px',
                            fontSize: Font.Mini,
                            color: Color.darkGrey,
                            $nest: {
                                '@media screen and (max-width: 930px)': {
                                    display: 'none'
                                }
                            }
                        },
                        '@media screen and (max-width: 930px)': {
                            marginRight: '0px',
                        }
                    }
                }, 
                '& .parafo': {
                    display: 'none',
                    $nest: {
                        '@media screen and (max-width: 930px)': {
                            display: 'flex',
                            fontSize: Font.Mini,
                            padding: '20px',
                            color: Color.darkGrey
                        }
                    }
                },
                '@media screen and (max-width: 930px)': {
                    width: '100%',
                    padding: '20px 0px',
                    flexDirection: 'column',
                },
                '@media screen and (max-width: 600px)': {
                    $nest: {
                        '& .imgDesktop':{
                            display: 'none'
                        },
                        '& .imgMobile':{
                            display: 'block',
                            width: '100%'
                        },
                        '& img': {
                            width: '100%'
                        }
                    }
                }
            }
        },
        '& .git': {
            marginTop: '20px'
        }
    }
})