import { style } from "typestyle"
import { Color, Font } from "../var"

export const fooStyle = style({
    width: 'calc(100% - 40px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: Color.darkGrey,
    padding: '50px 20px',
    borderTopColor: Color.lightGrey,
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    fontFamily: 'AvenirNext',
    $nest: {
        '& .info': {
            width: '100%',
            maxWidth: '1024px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            $nest: {
                '&.right': {
                    justifyContent: 'flex-end',
                    fontSize: Font.Mini,
                    paddingRight: '10%',
                    textAlign: 'center',
                    fontWeight: 100,
                    $nest: {
                        '@media screen and (max-width: 768px)': {
                            paddingRight: '0px'
                        },
                        '@media screen and (max-width: 600px)': {
                            textAlign: 'left',
                            marginBottom: '10px'
                        }

                    }
                },
                '& a': {
                    textDecoration:'none'
                },
                '& .item': {
                    width: '24%',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: Font.Normal,
                    $nest: {
                        '& .titulos': {
                            fontWeight: 'bold',
                            marginBottom: '20px'
                        },
                        '& .text': {
                            fontSize: Font.Mini,
                            marginBottom: '10px',
                            textDecoration: 'none',
                            color: Color.darkGrey,
                            flexDirection: 'column',
                            display: 'flex',
                            fontWeight: 100,
                            $nest: {
                                '& a': {
                                    color: Color.darkGrey,
                                    textDecoration: 'none',
                                    marginBottom: '10px',
                                },

                            }
                        },
                        '& .logo': {
                            transform: 'translatey(-22px)',
                            $nest: {
                                '@media screen and (max-width: 768px)': {
                                    transform: 'translatey(0px)',
                                    marginBottom: '20px'
                                }
                            }
                        },
                        '&.center':{
                            alignItems: 'center',
                            $nest: {
                                '& a':{
                                    textDecoration: 'none',
                                    $nest: {
                                        '&:visited':{
                                            color: Color.darkGrey
                                        }
                                    }
                                },
                                '& .text': {
                                    textAlign: 'center',
                                },
                                '@media screen and (max-width: 768px)': {
                                    alignItems: 'flex-start',
                                    $nest: {
                                        '& .text': {
                                            textAlign: 'left'
                                        },
                                    }
                                },
                                '@media screen and (max-width: 600px)': {
                                    marginBottom: '0px',
                                }
                            }
                        },
                        '@media screen and (max-width: 768px)': {
                            width: '100%',
                            marginBottom: '30px'
                        }
                    }
                },
                '@media screen and (max-width: 768px)': {
                    flexDirection: 'column',
                }
            }
        },
        '& .contMade':{
            fontSize: Font.Mini,
            color: Color.orange,
            fontFamily:'AvenirNext',
            textDecoration: 'none',
            $nest: {
                '@media screen and (max-width: 600px)': {
                    marginTop: '20px'
                }
            }
        }
    }
})