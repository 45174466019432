import  React from 'react';
import { faTimes, faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { message } from './style'

const Message = ({children, close, success}) => {
    return(
        <div className={`${message.cont} ${success? 'success': ''}`}>
            <div className={message.contClose}><FontAwesomeIcon className={`close ${success? 'success': ''}`} icon={faTimes} onClick={() => close()}/></div>
            <div className={message.contMessage}>
                <FontAwesomeIcon className='icon' icon= {success ? faCheckCircle:faTimesCircle} />
                <label>{children}</label>
            </div>
        </div>
    );
};
export default Message;