/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Link } from 'react-router-dom'
// style
import { galeryStyle } from '../../../style/utils/galery/style'

const Galery = ({list, className}) => {
    return(
        <div className={`${galeryStyle} ${className}`} >
            {list.map((item,i)=> <Link  onClick={() => item.to && window.scrollTo(0, 0)}  to={item.to||'#'} ><img key={i} src={item.img} /> </Link> )}
        </div>
    )
}

export default Galery