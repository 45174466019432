import { style } from "typestyle"

/* Colores WEB */
export const Color = {
   white: '#FFFFFF',
   black: '#000000',
   blue: '#174BDC',
   orange: '#FF5000',
   mediunOrange: '#CC270033',
   lightOrange: '#FFEFE7',
   darkGrey: '#666666',
   lightGrey: '#999999',
   lightWhite: '#f7f7f7',
   lightBlack: '#333333',
   lightBlue: '#d2e6f1',
   semiTrans: 'rgba(0,0,0,0)',
   linhtGreen: '#d2d8cf',
   green: '#767c73',
   lifeGreen: '#f7f7f7',
   danger: '#fcf1f1',
   dangertwo: '#ffd5cd'
}

export const Font = {
   MegaBig: '60px',
   Grande: '45px',
   Title: '35px',
   Mediun: '20px',
   Normal: '18px',
   Mini: '15px',
   micro: '10px'
}

export const Btn = style({
   minWidth: '300px',
   padding: '15px 20px',
   textAlign: 'center',
   textDecoration: 'none',
   fontWeight: 'bold',
   cursor: 'pointer',
   backgroundColor: Color.white,
   fontSize: Font.Mini,
   appearance: 'none',
   border: 'none',
   $nest: {
      '&:focus': {
         outline: 'none'
      }
   }
})

export const BtnTwotext = style({
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   justifyContent: 'center',
   minHeight: '50px',
   $nest: {
      '& .initialText': {
         fontSize: Font.Mini,
         textDecoration: 'none',
         transition: 'font-size 0.2s ease-out 0.2s'
      },
      '& .textHover': {
         fontSize: '0px',
         textDecoration: 'none',
         transition: 'font-size 0.2s ease-in'

      },
      '&:disabled':{
         cursor: 'no-drop',
         color: Color.darkGrey,
         $nest: {
            '& .initialText': {
               fontSize: Font.Mini,
               transition: 'none'
            },
            '& .textHover': {
               fontSize: '0px',
               transition: 'none',
            },
            '&:hover': {
               backgroundColor: Color.white,
               color: Color.darkGrey,
               $nest: {
                  '& .initialText': {
                     fontSize: Font.Mini,
                     transition: 'none'
                  },
                  '& .textHover': {
                     fontSize: '0px',
                     transition: 'none',

                  }
               }
            }
         }
      },
      '&:hover':{
         backgroundColor: Color.black,
         color: Color.white,
         $nest: {
            '& .initialText': {
               fontSize: 0,
               transition: 'font-size 0.1s ease-in'
            },
            '& .textHover': {
               fontSize: Font.Mini,
               transition: 'font-size 0.2s ease-out 0.2s'
            }
         }
      }
   }
})

export const Input = style({
   backgroundColor: 'transparent',
   borderWidth: '0px 0px 2px 0px',
   borderColor: Color.lightBlack,
   borderStyle: 'solid',
   padding: '10px 15px'
})