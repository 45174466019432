/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{ useContext,  useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

// context
import { MenuContext } from '../../context/menu'

//Style
import { menuStyle } from '../../style/menu/style'

const Menu = () => {
  const {
    MenuItems,
    ViewMenu, setViewMenu,
    menuToogleStyle, menuTooglePlay,
    open, setOpen

  } = useContext(MenuContext)
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const refFixed = useRef(null)
  const menuView = () => {
    setViewMenu(!ViewMenu)
    menuTooglePlay(!ViewMenu)
    setOpen(!ViewMenu)
  }
  console.log(open)
  const logoLoader = () =>{
    switch (location.pathname) {
      case '/infinito':
        return 'https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/alun-logo-black.png'
      case '/caso/uniRosario':
      case '/caso/copaTayrona':
      case '/caso/barcaAcademy':
        return 'https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/Logo%20Alu%CC%88n%20general.png'
      default:
        return 'https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/alun-logo-main.png'
    }
  }
  const transpNav = () => {
    switch (location.pathname) {
      case '/caso/uniRosario':
      case '/caso/copaTayrona':
      case '/caso/barcaAcademy':
        return 'transpNav';
      default:
        return '';
    }
  }

  useEffect(() => {
      window.addEventListener('scroll', e => {
        if(e.target.scrollingElement.scrollTop>30 ) {
          refFixed.current.classList.add('scroll')
        }else{
          refFixed.current.classList.remove('scroll')
        }
      })

  }, )

  return (
    <div className={menuStyle}>
        <div ref={refFixed} className={`fixed ${transpNav()}`}>
          <div className="menu">
            <Link className="logo" to='/' onClick={() => window.scrollTo(0,0,)} >
              <img src={logoLoader()}  alt="LogoAlun"/>
            </Link>
            <div className={`hamburgerIcon` + (open ? ' active' :'') } onClick={()=> {menuView()}} >
              <div className="lines line-top"></div>
              <div className="lines line-mid"></div>
              <div className="lines line-buttom"></div>
            </div>
          </div>
        </div>
         <div className="toogle"  style={menuToogleStyle}>
          <div className="nav">
            <div className="menu">
              <div className="left">
                <Link className="logo" to='/' onClick={()=>{ menuView(); window.scrollTo(0,0,)}} >
                  <img src="https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/alun-logo-black.png"  alt="LogoAlun"/>
                </Link>
                <div className="login">
                  <a href="https://erp.alunideas.co/#/auth/login" target="_blank">
                  <img src="https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/Login%403x.png"  alt="LogoAlun"/></a>
                </div>
              </div>
              <div className="right" >
                <div className="lang">
                  <div className="item" onClick={()=> { i18n.changeLanguage('es'); menuView() } } >{t('menu.lang.es')}</div>
                  <div className="item right" onClick={()=> { i18n.changeLanguage('en');  menuView() } } >{t('menu.lang.en')}</div>
                </div>
              </div>
            </div>
            <div className="list">
              {MenuItems.map((item, i)=><Link key={i} to={item.link} className="link" onClick={()=>{ menuView();window.scrollTo(0,0,) }} >{t(`menu.${item.label}`)}</Link>)}
            </div>
            <div className="social">
              <div className="lang">
                <div className="item" onClick={()=> { i18n.changeLanguage('es'); menuView() } } >{t('menu.lang.es')}</div>
                <div className="item right" onClick={()=> { i18n.changeLanguage('en');  menuView() } } >{t('menu.lang.en')}</div>
              </div>
              <a className="red" href="https://www.instagram.com/alunideas" target="_blank" >
                <FontAwesomeIcon  icon={faInstagram} />
              </a>
              <a className="red" href="https://twitter.com/alunideas" target="_blank" >
                <FontAwesomeIcon  icon={faTwitter} />
              </a>
              <a className="red" href="https://www.facebook.com/alunideas" target="_blank">
                <FontAwesomeIcon  icon={faFacebook} />
              </a>
              <a className="red" href="https://www.linkedin.com/company/alun-ideas" target="_blank">
                <FontAwesomeIcon  icon={faLinkedinIn} />
              </a>
              <a className="red right" href="https://erp.alunideas.co/#/auth/login" target="_blank">
                {t('menu.mialun')}
              </a>
            </div>
          </div>
        </div> 
    </div>
  );
}

export default Menu;