import { style } from "typestyle"

import { Color, Font } from '../var'

export const menuStyle = style({
    width:'100%',
    display:'flex',
    flexDirection:'row',
    flexWrap: 'wrap',
    justifyContent:'center',
    zIndex: 10,
    position: 'fixed',
    fontFamily: 'AvenirNext',
    top: '0px',

    $nest:{
        '& .fixed': {
            width: 'calc(100% - 40px)',
            backgroundColor: Color.white,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            height: 'calc(105px - 40px)',
            $nest: {
                '& .menu': {
                    width: '100%',
                    maxWidth: '1024px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    $nest: {
                        '& .hamburgerIcon': {
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            cursor: 'pointer',
                            display: 'flex',
                            position: 'relative',
                            zIndex: '10',
                            backgroundColor:Color.white,
                            $nest: {
                                '& .lines': {
                                    position: "absolute",
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate (-50%, -50%)',
                                    height: '3px',
                                    width: '50%',
                                    backgroundClip: 'padding-box',
                                    background: Color.black,
                                    transition: ' all 0.5s cubic-bezier(0.86, 0, 0.07, 1)'
                                },
                                '& .lines:nth-child(1)':{
                                    transform:' translate(-50%, calc(-50% - 8px))',
                                },
                                '& .lines:nth-child(2)':{
                                    transform:' translate(-50%, calc(-50% + 0px))',
                                },
                                '& .lines:last-child': {
                                    transform: 'translate(-50%, calc(-50% + 8px))'
                                },
                            }
                        },

                        '& .active' :{
                            backgroundColor: Color.black,
                            '& .lines':{
                                background: Color.white,
                            },
                            '& .lines:nth-child(1)'  : {
                                transform:' translate(-50%, -50%) rotate(45deg)'
                            },
                            '& .lines:nth-child(2)': {
                                opacity: 0
                            },
                            '& .lines:nth-child(3)': {
                                transform:'translate(-50%, -50%) rotate(-45deg)'
                            }
                        },
                    }
                },
                '&.transpNav': {
                    backgroundColor: 'transparent',
                },
                '&.scroll': {
                    backgroundColor: Color.semiTrans
                }
            }
        },
        '& .toogle': {
            backgroundColor: Color.orange,
            width: '100%',
            height: '100%',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            bottom: '105px',
            $nest: {
                '& .nav': {
                    padding: '20px',
                    width: '100%',
                    maxWidth: '1024px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    $nest: {
                        '& .menu': {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: 'calc(100% - 50px)',
                            $nest: {
                                '& .left': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    $nest: {
                                        '& .logo': {
                                            marginRight: '30px'
                                        },
                                        '& .login': {
                                            width: '50px',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            $nest: {
                                                '@media screen and (max-width: 768px)': {
                                                    display: 'none'
                                                },
                                                '& img': {
                                                    width: '100%',
                                                    $nest: {
                                                        '&:hover': {
                                                            content: 'url("https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/alun-login-hover.png")'  
                                                          },
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                '& .right': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    $nest: {
                                        '& .lang': {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginRight: '20px',
                                            justifyContent: 'space-between',
                                            $nest: {
                                                '@media screen and (max-width: 768px)':{
                                                    display: 'none'
                                                },
                                                '& .item': {
                                                    color: Color.white,
                                                    cursor: 'pointer',
                                                    padding: '2px 15px',
                                                    $nest: {
                                                        '&:hover':{
                                                            color: Color.black
                                                        }
                                                    }
                                                },
                                                '& .right': {
                                                    borderLeftColor: Color.white,
                                                    borderLeftWidth: '1px',
                                                    borderLeftStyle: 'solid'
                                                }
                                            }
                                        },
                                        '& .close': {
                                            width: '50px',
                                            height: '50px',
                                            backgroundColor: Color.black,
                                            color: Color.white,
                                            fontSize: '30px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: '50%',
                                            fontFamily: 'AvenirNext',
                                            fontWeight: 'normal',
                                            cursor: 'pointer'
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                '& .list': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    $nest: {
                        '& .link': {
                            color: Color.white,
                            fontWeight: 'bold',
                            textDecoration: 'none',
                            fontSize: Font.MegaBig,
                            textAlign: 'end',
                            $nest: {
                                '&:hover':{
                                    color: Color.black,
                                    textDecoration: 'underline'
                                },
                                '@media screen and (max-width: 640px)':{
                                    fontSize: Font.Grande
                                },
                                '& .item': {
                                    color: Color.white,
                                    cursor: 'pointer',
                                    padding: '2px 15px'
                                },
                                '& .right': {
                                    borderLeftColor: Color.white,
                                    borderLeftWidth: '1px',
                                    borderLeftStyle: 'solid'
                                }
                            }
                        }
                    }
                },
                '& .social': {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    $nest: {
                        '& .lang': {
                            display: 'none',
                            width: '100%',
                            flexDirection: 'row',
                            marginBottom: '20px',
                            $nest: {
                                '@media screen and (max-width: 768px)': {
                                    display: 'flex'
                                },
                                '& .item': {
                                    color: Color.white,
                                    cursor: 'pointer',
                                    padding: '2px 15px'
                                },
                                '& .right': {
                                    borderLeftColor: Color.white,
                                    borderLeftWidth: '1px',
                                    borderLeftStyle: 'solid'
                                }
                            }
                        },
                        '& .red': {
                            padding: '15px',
                            color: Color.white,
                            cursor: 'pointer',
                            fontSize: Font.Mediun,
                            textDecoration: 'none',
                            $nest: {
                                '&:hover':{
                                    color: Color.black
                                },
                                '&:visited':{
                                    color: Color.white
                                },
                                '&.right': {
                                    display: 'none',
                                    flex: '1 1 auto',
                                    textAlign: 'right',
                                    paddingRight: '0px',
                                    $nest: {
                                        '@media screen and (max-width: 768px)': {
                                            display: 'block'
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

})