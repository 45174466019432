import React, {createContext, useState}  from 'react'
const ContactenosContext = createContext()
const { Provider, Consumer } = ContactenosContext

const ContactenosProvider = ({children}) =>  {
    const [GaleryList, setGaleryList] = useState([
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Secci%C3%B3n%20Contacto/alun-registro-eventos.png'},    
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Secci%C3%B3n%20Contacto/alun-masctg-desarrollo-app.png'},    
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Secci%C3%B3n%20Contacto/alun-matrix-international-marca.png'},    
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Secci%C3%B3n%20Contacto/alun-masterbeer-redes-sociales.png'},    
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Secci%C3%B3n%20Contacto/alun-saenzfety-marca.png'},    
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Secci%C3%B3n%20Contacto/alun-copatayrona-redes-sociales.png'},    
    ])
    const [SkillList, setSkillList] = useState([
        {title: 'Brand Identity', des: 'Toda gran marca debe tener una razón de ser: para sus colaboradores, para sus clientes y para la sociedad. Nosotros ayudamos a las marcas a encontrarla y expresarla a través de una identidad impactante e inspiradora que genera valor para las empresas.'},
        {title: 'Packaging Design', des: 'Desarrollamos empaques atractivos e innovadores que conectan emocionalmente con los consumidores a partir de un entendimiento profundo del mercado y de la categoría.'},
        {title: 'Retail Design', des: 'Desde sedes corporativas hasta restaurantes y tiendas comerciales, ayudamos a las marcas a revitalizar sus experiencias con los consumidores y a reinventar el papel que juega el Retail Branding en su estrategia de negocio.'},
        {title: 'Digital Communication', des: 'La presencia digital se ha convertido en la presencia  en el mundo real. Creamos estrategias de contenido digital, utilizamos formatos innovadores, conocemos las últimas tendencias y las ejecutamos a través del más alto estándar  de diseño.'},
    ])
    const [ClientName, setClientName] = useState('')
    const [ClientMail, setClientMail] = useState('')
    const [ClientPhone, setClientPhone] = useState('')
    const [ClientCompany, setClientCompany] = useState('')
    const [ClientMesaje, setClientMesaje] = useState('')
    // talen
    const [TalenName, setTalenName] = useState('')
    const [TalenMail, setTalenMail] = useState('')
    const [TalenPhone, setTalenPhone] = useState('')
    const [TalenArea, setTalenArea] = useState('')
    const [TalenMensaje, setTalenMensaje] = useState('')
    const [TalenFile, setTalenFile] = useState('')
    const [File, setFile] = useState(true)

    return(
        <Provider
            value={{
                GaleryList, setGaleryList,
                SkillList, setSkillList,
                ClientName, setClientName,
                ClientMail, setClientMail,
                ClientPhone, setClientPhone,
                ClientCompany, setClientCompany,
                ClientMesaje, setClientMesaje,
                // telen
                TalenName, setTalenName,
                TalenMail, setTalenMail,
                TalenPhone, setTalenPhone,
                TalenArea, setTalenArea,
                TalenMensaje, setTalenMensaje,
                TalenFile, setTalenFile,
                File, setFile
            }}
        >
           {children} 
        </Provider>
    )
}

export { ContactenosProvider, Consumer as ContactenosConsumer, ContactenosContext }