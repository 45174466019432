import { style } from "typestyle"

import { Color, Font } from '../var'

export const contactenosStyle = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'AvenirNext',
    $nest: {
        '& .banner': {
            $nest: {
               '& .cont': {
                   $nest: {
                        '& .info': {
                            maxWidth: '660px',
                            $nest: {
                                '@media screen and (max-width: 930px)': {
                                    maxWidth: '100%',
                                }
                            }
                        },
                        '& .text': {
                            maxWidth: '540px'
                        }
                   }
               }
            }
        },
        '& .contContact': {
            background: `linear-gradient(0deg, ${Color.white} 0%, ${Color.white} 50%, ${Color.lightOrange} 50%, ${Color.lightOrange} 100%)`,
            width: 'calc(100% - 40px)',
            padding:'100px 20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            $nest: {
                '& .cont': {
                    width: '100%',
                    maxWidth: '1024px',
                    display: 'flex',
                    flexDirection: 'column',
                    $nest: {
                        '& .text': {
                            fontWeight: 'bold',
                            fontSize: Font.Normal,
                            marginBottom: '30px',
                            $nest: {
                                '&.textBlock': {
                                    padding: '20px 0px',
                                    $nest: {
                                        '@media screen and (max-width: 950px)':{
                                            padding: '20px',
                                        }
                                    }
                                },
                                '@media screen and (max-width: 950px)':{
                                    padding: '0 20px',
                                },
                                '@media screen and (max-width: 600px)': {
                                    width: '70%'
                                }
                            }
                        },
                        '& .contForm': {
                            width: '100%',
                            backgroundColor: Color.lightBlack,
                            padding: '50px 0px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            $nest: {
                                '& .text':{
                                    width: '40%',
                                    fontSize: Font.Title,
                                    fontWeight: 'bold',
                                    color: Color.white,
                                    $nest: {
                                        '@media screen and (max-width: 950px)': {
                                            order: 2,
                                            marginTop: '50px',
                                            width: '100%',
                                            fontSize: Font.Mediun
                                        }
                                    }
                                },
                                '& .form': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    width: '430px',
                                    $nest: {
                                        '& input': {
                                            width: '100%',
                                            padding: '10px 0px',
                                            borderColor: Color.white,
                                            color: Color.white,
                                            fontSize: Font.Normal,
                                            marginBottom: '10px',
                                            $nest: {
                                                '&::placeholder': {
                                                    color: Color.white,
                                                    fontSize: Font.Mini,
                                                    fontWeight: 100
                                                },
                                                '&:focus':{
                                                    outline: 'none',
                                                    borderColor: Color.darkGrey
                                                }
                                            }
                                        },
                                        '& .btn': {
                                            marginTop: '50px',
                                            $nest: {
                                                '&.hoverOrange': {
                                                    $nest: {
                                                        '&:hover': {
                                                            backgroundColor: Color.orange,
                                                        }
                                                    }
                                                },
                                                '@media screen and (max-width: 768px)':{
                                                    width:'100%'
                                                }
                                            }
                                        },
                                        '& .checkbox':{
                                            width: '100%',
                                            padding: '15px 0px',
                                            display: 'flex',
                                            justifyContent:'space-between',
                                            color: Color.white,
                                            fontSize: Font.Mini,
                                            $nest: {
                                                '& .check': {
                                                    borderColor: Color.white,
                                                    '@media screen and (max-width: 600px)': {
                                                        margin: '10px 0'
                                                    }
                                                },
                                                '@media screen and (max-width: 600px)': {
                                                    flexDirection: 'column'
                                                }
                                            }
                                        },
                                        '& .dzu-dropzone': {
                                            overflow: 'hidden',
                                            borderWidth: '0 0 2px 0',
                                            borderRadius: '0px',
                                            borderColor: Color.white,
                                            minHeight: '50px',
                                            $nest: {
                                                '& .dzu-inputLabel': {
                                                    fontSize: Font.Mini,
                                                    fontFamily: 'AvenirNext',
                                                    fontWeight: 600,
                                                    justifyContent: 'flex-start',
                                                    color: Color.white
                                                },
                                                '& .dzu-submitButtonContainer': {
                                                    display: 'none'
                                                },
                                                '& .dzu-previewFileName': {
                                                    fontSize: Font.Mini,
                                                    fontFamily: 'AvenirNext',
                                                    fontWeight: 100, 
                                                    color: Color.white
                                                }
                                            }
                                        },
                                        '@media screen and (max-width: 950px)':{
                                            order: 1,
                                            width: '100%'
                                        }
                                    }
                                },
                                '&.contCand': {
                                    padding: '0px',
                                    justifyContent: 'space-between',
                                    backgroundColor: Color.orange,
                                    $nest: {
                                        '& .form': {
                                            width: '100%',
                                            padding: '20px'
                                        },
                                        '@media screen and (max-width: 950px)': {
                                            width: '100%'
                                        }
                                    }
                                },
                                '& img': {
                                    width: '50%',
                                    $nest: {
                                        '@media screen and (max-width: 950px)': {
                                            width: '100%',
                                            order: 3
                                        }
                                    }
                                },
                                '@media screen and (max-width: 950px)': {
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start',
                                    width: 'calc(100% - 40px)',
                                    padding: '50px 20px',
                                }
                            }
                        },
                        '& .map': {
                            borderWidth: '0px',
                            marginBottom: '30px',
                        }
                    }
                },
                '@media screen and (max-width: 950px)':{
                    width: '100%',
                    padding:'100px 0px',
                    background: `linear-gradient(0deg, ${Color.white} 0%, ${Color.white} 60%, ${Color.lightOrange} 60%, ${Color.lightOrange} 100%)`,

                },
                '@media screen and (max-width: 890px)': {
                    padding:'40px 0px',
                }
            }
        }
    }
})