import React, {createContext, useState}  from 'react'
const InfinitoContext = createContext()
const { Provider, Consumer } = InfinitoContext

const InfinitoProvider = ({children}) =>  {
    const [GaleryList, setGaleryList] = useState([
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20INFINITO/infinito-bluedots-marca.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20INFINITO/infinito-ninet-marca.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20INFINITO/infinito-paseocolon-marca.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20INFINITO/infinito-candela-marca.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20INFINITO/infinito-porta-marca.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20INFINITO/infinito-4d-marca.png'}
    ])
    const [SkillList, setSkillList] = useState([
        {title: 'infinito.skill.brand.title', des: 'infinito.skill.brand.description'},
        {title:  'infinito.skill.packaging.title', des: 'infinito.skill.packaging.description'},
        {title: 'infinito.skill.retail.title', des: 'infinito.skill.retail.description'},
        {title: 'infinito.skill.digital.title', des: 'infinito.skill.digital.description'}
    ])
    const [ClientName, setClientName] = useState('')
    const [ClientMail, setClientMail] = useState('')
    const [ClientPhone, setClientPhone] = useState('')
    const [ClientCompany, setClientCompany] = useState('')
    const [ClientMesaje, setClientMesaje] = useState('')
    // talen
    return(
        <Provider
            value={{
                GaleryList, setGaleryList,
                SkillList, setSkillList,
                ClientName, setClientName,
                ClientMail, setClientMail,
                ClientPhone, setClientPhone,
                ClientCompany, setClientCompany,
                ClientMesaje, setClientMesaje,
            }}
        >
           {children} 
        </Provider>
    )
}

export { InfinitoProvider, Consumer as InfinitoConsumer, InfinitoContext }