export const es = {
    title: 'Alun Ideas',
    menu: {
        marca: 'Marca',
        laboratorio: 'Laboratorio Digital',
        innovacion: 'Innovación & Desarrollo',
        contactenos: 'Contáctenos',
        infinito: 'Infinito',
        mialun: 'Mi Alün',
        lang: {
            es: 'ES',
            en: 'EN'
        }
    },
    footer: {
        contactenos: {
            title: 'Contáctenos'
        },
        descubranos: {
            title: 'Descúbranos',
            text: 'Trabajemos juntos',
            text2: 'Bolsa de empleo'
        },
        partners: {
            title: 'Partners',
            infinito: 'INFINITO',
            greater: 'Greater Bogotá Convention Bureau'
        },
        aviso: 'Aviso de privacidad',
        politicas: 'Política de datos',
        madeAlun: 'Made with love by alunideas.com'
    },
    home: {
        banner: {
            title: {
                text1: '¡Hola! somos',
                textColor: 'Alün Ideas',
                text2: 'the storytelling house.',
            },
            parafo: 'Los 3 pilares que fundamentan nuestros desarrollos son: compromiso con el cliente, innovación en los procesos para generar resultados que sorprenden y atención absoluta a los valores de las marcas.'
        },
        task: {
            phrase: 'Mucho más de lo que ya conoce',
            subtitle: 'Creamos estrategias que responden a las necesidades de nuestros clientes.',
            paragraph: 'Con nuestro modelo creativo que tiene como origen el storytelling, queremos contar la historia que quiere ser escuchada por su público objetivo.',
            btn: 'Quiero saber más de Alün Ideas',
            btnHover: '¡Trabajemos Juntos!',
            items: {
                analisis: 'Análisis e investigación',
                formulacion: 'Formulación de estrategia',
                desarrollo: 'Desarrollo e implementación',
                remoto: 'Retorno de la inversión (ROI)',
            },
            title: {
                text: 'Además creemos en la innovación',
                text2: 'y en los resultados.'
            }
        },
        projects: {
            phrase: 'Estos son algunos de los proyectos que hemos desarrollado para clientes, que ahora son amigos de la casa.',
            text: ' Pero también puede echar un vistazo a lo demás que hemos hecho.',
            btn: 'Quiero ver más',
            btnHoverForm: '¡Te vas a sorprender!',
            btnHover: '¡Trabajemos Juntos!'
        },
        agency: {
            phrase: 'Made with love by alunideas.com',
            title: 'Somos una agencia consultora y de servicios integrales, pero, esto es lo que hacemos mejor.',
            items: {
                brand: {
                    title: 'Marca',
                    info: 'Creamos estrategias que logran comunicar la esencia de cada marca para que proyecten lo que es realmente importante para su público. Su marca debe sobresalir, pero brindando claridad con lo que está ofreciendo al mercado.'
                },
                laboratory: {
                    title: 'Laboratorio Digital',
                    info: 'Creemos en el crecimiento digital como canal de comunicación directo donde las buenas estrategias ponen el juego a su favor. Trabajamos con resultados y para alcanzarlos somos fieles a la planeación excesiva, proyectada a corto, mediano y largo plazo.'
                },
                developer: {
                    title: 'Innovación & Desarrollo',
                    info: 'Nuestro objetivo es implementar las herramientas digitales necesarias para lograr los KPI’s planteados según el plan de su marca, nos apoyamos en las nuevas tecnologías y desarrollo de nuevos formatos para contar con los elementos necesarios para cumplir cada objetivo.'
                }
            }
        },
        team: {
            subtitle: 'Creemos en las personas con espíritus inmensos',
            title: 'Este es nuestro equipo de creadores.'
        },
        companys: {
            subtitle: 'Nos gusta la buena compañía',
            title: 'Hacemos parte de grandes ideales.',
            items: {
                infinito: {
                    title: 'Infinito Consultores',
                    description: 'Consultora de branding creada en el 2005, enfocada en ayudar a transformar marcas y negocios con soluciones que combinan la claridad del pensamiento estratégico y la audacia de ideas originales, expresadas en un diseño del mas alto estándar.'
                },
                greater: {
                    title: 'Greater Bogotá Convention Bureau',
                    description: 'Es una entidad sin ánimo de lucro que tiene como objetivo liderar la estrategia de promoción de Bogotá como sede para eventos internacionales, como congresos, reuniones e incentivos empresariales.'
                }
            }
        }
    },
    brand: {
        banner: {
            text1: 'La primera',
            textColor: 'impresión',
            text2: ', es lo que sus clientes van a recordar.',
            paragraph: 'Su marca debe verse bien en cualquier plataforma: canales digitales, impresos y en todos los espacios de los que haga parte. De esta manera, comunicará y se posicionará de forma eficiente en la mente de sus consumidores.'
        },
        contDescrip: {
            des: 'En Alün Ideas sabemos que las marcas van más allá de la diferenciación visual, deben representar de manera efectiva los valores y atributos, para transformar la idea de marca en una experiencia social.',
            info: {
                title: 'Estrategia de marca gráfica',
                text: 'Todo lo que rodee su identidad visual debe ser único y encajar con lo que quiere comunicar.',
                consultancyTitle: 'Consultoría de marca para plataformas tradicionales y digitales',
                consultancyText: 'Respondemos el interrogante del estado real de su marca, diferenciando por tipos de canales de comunicación.',
                eventsTitle: 'Identificador para eventos',
                eventsText: 'Una actividad con fechas exclusivas necesita posicionarse, y la mejor forma es con un identificador que comunique la esencia de la experiencia.'
            },
            contSob: {
                text: 'Su marca debe ser única, capaz de sobresalir.'
            }
        }
    },
    laboratory: {
        banner: {
            text1: 'Trabajar con KPI’s concretos es la mejor',
            textColor: 'estrategia.',
            text2: '',
            parafo: 'Nuestra gestión de canales digitales, está enfocada en KPI´s comerciales con mucha creatividad. Tener objetivos claros permiten optimizar las plataformas para promocionar y posicionar su marca de una forma más eficiente.'
        },
        contImp: {
            title: 'Impulse su marca en las plataformas adecuadas.',
            description: 'Creemos en las plataformas digitales como canal de comunicación directo, donde la estrategia ponen el juego a su favor. Trabajamos con resultados con base en la planeación proyectada a corto, mediano y largo plazo.'
        },
        skills: {
            social: {
                label: 'Social Media',
                description: 'Consultoría para el desarrollo de un plan de contenidos en redes sociales, enfocado en el crecimiento y fortalecimiento de comunidades digitales.'
            },
            marketing: {
                label: 'Marketing Digital',
                description: 'Planificación de campañas en plataformas digitales,para obtener resultados comerciales.',
            },
            seo: {
                label: 'SEO / SEM',
                description: 'Posicionamiento efectivo en buscadores web, generando contenidos orgánicos y de pauta.'
            },
            inBound: {
                label: 'InBound - OutBound marketing',
                description: 'Optimización de recursos para la captación de clientes, con estrategias para la conversión de objetivos comerciales.'
            }
        }
    },
    innovation: {
        banner: {
            text1: 'Descubra cómo la ',
            textColor: 'tecnología',
            text2: ' puede ser su mejor aliado.',
            parafo: 'Nos apoyamos en las nuevas tecnologías y en el desarrollo de nuevos formatos, para cumplir con cada uno de los objetivos digitales de nuestros clientes. <br/><br/>Podemos comenzar un proyecto desde ceros o retomar uno que tenga en pausa. Nuestro objetivo es lograr que la experiencia de los usuarios sea la que su marca quiere y debe transmitir.'
        },
        contImp: {
            title: 'Nuestros servicios dan respuesta a sus necesidades o a las de su cliente.'
        },
        skills: {
            webDev: {
                label: 'Diseño y desarrollo web',
                description: 'Creamos sitios web según los requerimientos específicos de nuestros clientes, donde la experiencia de usuario es el factor principal de comunicación.'
            },
            appDev: {
                label: 'Diseño y desarrollo de aplicaciones móviles',
                description: 'Creación de aplicaciones móviles, cumpliendo requerimientos corporativos y comerciales, herramientas ideales para su marca.'
            },
            webApp: {
                label: 'Diseño y desarrollo de web apps',
                description: 'Creación de aplicaciones alojadas en sitios web para eventos temporales, una experiencia digital que potenciará su marca.'
            }
        }
    },
    contact: {
        banner: {
            text1: 'Hablemos y descubra todo lo que tenemos para ',
            textColor: 'ofrecer.',
            text2: '',
            parafo: 'En Alün Ideas conectamos marcas con sus audiencias a través de un modelo creativo, en el que el desarrollo tecnológico, la innovación, la consultoría y la estrategia, son ejes fundamentales para lograr resultados efectivos.'
        },
        contContact: {
            text1: 'Contáctenos y empecemos a',
            text2: 'planificar su estrategia.'
        },
        form: {
            name: 'Nombre completo*',
            email: 'Email*',
            tel: 'Teléfono de contacto*',
            company: 'Compañía',
            message: 'Mensaje*',
            message2:'¿Qué esta buscando?',
            download:'Descargar'

        },
        map: {
            text1: 'Continuamente estamos en la búsqueda de talentos,',
            text2: ' queremos conocer cuál es el suyo, envíenos su CV.'
        },
        form2: {
            name: 'Nombre completo*',
            email: 'Email*',
            phone: 'Teléfono de contacto*',
            interest: 'Área de interés*',
            option1: 'Diseño',
            option2: 'Cuentas',
            option3: 'Desarrollo',
            message: 'Mensaje*',
            cv: 'Adjunte su CV*'
        },
        button: {
            speak: 'Enviar',
            readychat: '¡Queremos sorprenderte!',
            areYouReady: 'Enviar',
            applyNow: 'Veamos tu potencial'
        },
        message: {
            name: 'El nombre es requerido',
            mail: 'El mail no es valido',
            phone: 'El telefono es requerido',
            mesaje: 'El mensaje es requerido',
            area: 'El area de interes es requerida',
            file: 'Adjunta tu CV',
            errorDefault: 'Uy algo salio mal...',
            correctValidate: 'Muy pronto estaremos en contacto, Gracias por contar con nosotros',
            correctMessageHome: 'Gracias por interesarte en nuestro trabajo'
        }
    },
    infinito: {
        banner: {
            text1: 'El ',
            textColor: 'Branding',
            text2: ' es <br class="br"/>una herramienta de gestión.',
            parafo: 'Coincidir con la Consultora de Branding más grande del Perú como un aliado estratégico para aumentar el valor del servicio de nuestra agencia, es un logro que nos enorgullece.'
        },
        contInfo: {
            text1: ' Nuestro objetivo siempre será ofrecer los mejores productos a nuestros clientes, por eso logramos una alianza estratégica con INFINITO, la consultora de branding más grande del Perú.',
            text2: 'Con la unión de nuestros servicios integrales en comunicación  digital y los servicios de Branding de INFINITO creamos una poderosa alianza en función al desarrollo de marcas.'
        },
        skill: {
            brand: {
                title: 'Brand Identity',
                description: 'Toda gran marca debe tener una razón de ser: para sus colaboradores, para sus clientes y para la sociedad. Nosotros ayudamos a las marcas a encontrarla y expresarla a través de una identidad impactante e inspiradora que genera valor para las empresas.'
            },
            packaging: {
                title: 'Packaging Design',
                description: 'Desarrollamos empaques atractivos e innovadores que conectan emocionalmente con los consumidores a partir de un entendimiento profundo del mercado y de la categoría.'
            },
            retail: {
                title: 'Retail Design',
                description: 'Desde sedes corporativas hasta restaurantes y tiendas comerciales, ayudamos a las marcas a revitalizar sus experiencias con los consumidores y a reinventar el papel que juega el Retail Branding en su estrategia de negocio.'
            },
            digital: {
                title: 'Digital Communication',
                description: 'La presencia digital se ha convertido en la presencia  en el mundo real. Creamos estrategias de contenido digital, utilizamos formatos innovadores, conocemos las últimas tendencias y las ejecutamos a través del más alto estándar  de diseño.'
            }
        },
        form: {
            text: 'Creamos marcas con potencial infinito, contáctenos.',
            name: 'Nombre completo*',
            email: 'Email*',
            tel: 'Teléfono de contacto*',
            company: 'Compañía',
            message: 'Mensaje*',
            button: {
                send: 'Envíar',
                weSend: 'Pronto lo contáctaremos'
            }
        },
        message: {
            name: 'El nombre es requerido',
            mail: 'El mail no es valido',
            phone: 'El telefono es requerido',
            mesaje: 'El mensaje es requerido',
            area: 'El area de interes es requerida',
            file: 'Adjunta tu CV',
            errorDefault: 'Uy algo salio mal...',
            correctValidate: 'Muy pronto estaremos en contacto, Gracias por contar con nosotros'
        }
    },
    rosario: {
        banner: 'Herramienta digital para el control de asistencia y acceso',
        contInfo: {
            challengeTitle: 'Nuestro reto',
            challengeDescription: 'Desarrollar e implementar una herramienta digital que permitiera a la Universidad del Rosario, tener control en tiempo real de sus eventos con la comunidad estudiantil. Esto, con el propósito de minimizar gastos logísticos y administrativos en sus actividades, al tiempo de poder llevar control sobre su ejecución.',
            solutionTitle: 'Nuestra solución',
            solutionDescription: 'Adecuamos a las necesidades especificas de la Universidad del Rosario. <br/>Registro Express, es nuestro sistema de registro de plataforma móvil que solo con tener un celular o tableta de cualquier sistema operativo le permite a los organizadores de eventos monitorear y controlar el acceso a su actividad durante el evento.'
        },
        contSob: {
            title: 'Qué logramos',
            item1: 'Optimizar y administrar de manera inteligente sus bases de datos.',
            item2: 'Realizar el envío masivo de correos electrónicos para la coordinación de actividades.',
            item3: 'Llevar un control actualizado de las operaciones y gestiones logísticas por evento.',
            item4: 'Mejorar la experiencia de acceso a los eventos de población con discapacidades cognitivas o físicas.',
            item5: 'Digitalizar a través de un código QR el acceso de estudiantes, permitiendo que el ingreso por evento fuera en menos de un minuto.'
        },
    },
    tayrona: {
        banner: 'La copa de cerveza artesanal más importante del país se toma el Tayrona.',
        contInfo: {
            challengeTitle: 'Nuestro reto',
            challengeDescription: 'La Copa Tayrona es una competencia dedicada al juzgamiento de cervezas 100% artesanales en Latinoamérica. Con la intención de posicionarse en la región, tras convertirse en miembro de la Liga de Maestros de la Cerveza, buscaba la renovación de su identidad visual bajo tres ideales: tradición, respeto máximo a los ingredientes y calidad.',
            solutionTitle: 'Nuestra solución',
            solutionDescription: 'Creamos una estrategia de marca con base en el trabajo artesanal y dedicado de la orfebrería y cerámica de la cultura amerindia oriunda de la región. Con sus elementos representativos, creamos una marca gráfica de fácil recordación, que destaca la cultura del lugar sede de la copa y el principal ingrediente de una buena cerveza independiente, el lúpulo.'
        },
        contChange: {
            before: 'Antes',
            after: 'Ahora'
        },
        contElement: {
            title: 'Elementos claves que se usaron para el desarrollo de la identidad gráfica de Copa Tayrona.',
            koguiTitle: 'Máscara ceremonial de la comunidad Kogui',
            koguiDescription: ' Esta comunidad utiliza mascaras en sus ceremonias y rituales de mayor significado y relevancia. Tayrona, es la copa cervecera independiente de mayor importancia en el país, por  la calidad de sus jueces, el alcance de convocatoria  y su vínculo con la liga latinoamericana más importante de cervezas artesanales.',
            florTitle: 'La flor de lúpulo como inspiración',
            florDescription: 'El lúpulo es el ingrediente primordial para toda cerveza, es utilizado por cada maestro cervecero como el ingrediente secreto que hace la diferencia en cada creación.'
        }
    },
    barca: {
        banner: 'Formación a través del deporte con la metodología del FC Barcelona.',
        contInfo: {
            challengeTitle: 'Nuestro reto',
            challengeDescription: 'Captar nuevos estudiantes para todas las sedes en Colombia, promocionar los Barça Academy Camps en cada temporada y aumentar la comunidad digital realizando un mantenimiento continuo de sus seguidores e interesados.'
        },
        requirements: {
            title: 'Los requerimientos y objetivos fueron:',
            item1: 'Generar leads calificados interesados en ser parte de la academia.',
            item2: 'Promocionar la IV Copa Barça Academy Las Américas.',
            item3: 'Administrar la comunidad en redes sociales y atención de PQR’s.',
            item4: ' Aumentar las interacciones y hacer crecer la comunidad con padres interesados en matricular a sus hijos en la academia.',
            item5: 'Posicionamiento digital de la Barça Academy.'
        },
        strategy: {
            title: 'Nuestra estrategia',
            item1: 'Creación de un plan de renovación de diseño y desarrollo web, en el que se implementó toda la información alojada en la página anterior, con mejoras en diseño, producción de textos y renovación de imagen digital.',
            item2: 'Vinculación del sitio web con el CRM de Alün Ideas para administrar y gestionar los leads obtenidos.',
            item3: 'Construcción de un plan de contenidos orgánicos y de pauta para plataformas de Google Ads y Facebook Ads con distribución mensual.',
            
        }
    },
    modal:{
        text: '¡Gracias por su  interés en nuestro trabajo!'
    }

}