/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'pretty-checkbox-react'
import '@djthoms/pretty-checkbox'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
// componet
import Banner from '../utils/banner'
import Galery from '../utils/galery'
import { validateGlobal, toastMessage } from '../utils/validateGlobal'
// context
import { ContactenosContext } from '../../context/contactenos'
// api
import { goClient, goTalen } from '../../api/contactenos'
// style
import { Color, Btn, BtnTwotext, Input } from '../../style/var'
import { contactenosStyle } from '../../style/contactenos/style'

const Contactenos = () => {
    const {
        GaleryList,
        ClientName, setClientName,
        ClientMail, setClientMail,
        ClientPhone, setClientPhone,
        ClientCompany, setClientCompany,
        ClientMesaje, setClientMesaje,
        // telen
        TalenName, setTalenName,
        TalenMail, setTalenMail,
        TalenPhone, setTalenPhone,
        TalenArea, setTalenArea,
        TalenMensaje, setTalenMensaje,
        TalenFile, setTalenFile,
        File, setFile

    } = useContext(ContactenosContext)

    const goClientContact = () => {
        const [valitate] = validateGlobal({
            ClientName: 'Require|String',
            ClientMail: 'Require|Email',
            ClientPhone: 'Require|String',
            ClientCompany: 'String',
            ClientMesaje: 'Require|String'
        }, {
            ClientName, ClientMail, ClientPhone, ClientCompany, ClientMesaje
        }, {
            message: {
                ClientName: t('contact.message.name'),
                ClientMail: t('contact.message.mail'),
                ClientPhone: t('contact.message.phone'),
                ClientMesaje: t('contact.message.mesaje'),
                ErrorDefault: t('contact.message.errorDefault'),
                CorrectValidate: ''
            },
            position: 'bottom-right',
            correctValidate: false
        },
        )
        if (valitate) {
            goClient(ClientName, ClientMail, ClientPhone, ClientCompany, ClientMesaje).then(
                () => {
                    setClientName('')
                    setClientMail('')
                    setClientPhone('')
                    setClientCompany('')
                    setClientMesaje('')
                    toastMessage({ message: t('contact.message.correctValidate'), position: 'bottom-right' })
                }
            ).catch(() => toastMessage({ message: t('contact.message.errorDefault'), position: 'bottom-right', error: true }))
        }
    }

    const goTalenContact = () => {
        const [valitate] = validateGlobal({
            TalenName: 'Require|String',
            TalenMail: 'Require|Email',
            TalenPhone: 'Require|String',
            TalenArea: 'Require|String',
            TalenFile: 'Require|String',
            TalenMensaje: 'Require|String'
        }, {
            TalenName,
            TalenMail,
            TalenPhone,
            TalenArea,
            TalenMensaje,
            TalenFile
        }, {
            message: {
                TalenName: t('contact.message.name'),
                TalenMail: t('contact.message.mail'),
                TalenPhone: t('contact.message.phone'),
                TalenArea: t('contact.message.area'),
                TalenMensaje: t('contact.message.mesaje'),
                TalenFile: t('contact.message.file'),
                ErrorDefault: t('contact.message.errorDefault'),
                CorrectValidate: ''
            },
            position: 'bottom-right',
            correctValidate: false
        },
        )
        console.log('message xd', valitate)
        if (valitate) {
            goTalen(TalenName,
                TalenMail,
                TalenPhone,
                TalenArea,
                TalenMensaje,
                TalenFile).then(
                    () => {
                        setTalenName('')
                        setTalenMail('')
                        setTalenPhone('')
                        setTalenArea('')
                        setTalenMensaje('')
                        setTalenFile('')
                        setFile(null)
                        toastMessage({ message: t('contact.message.correctValidate'), position: 'bottom-right' })
                    }
                ).catch(() => toastMessage({ message: t('contact.message.errorDefault'), position: 'bottom-right', error: true }))
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    // specify upload params and url for your files
    const getUploadParams = () => ({ url: 'https://httpbin.org/post' });

    // called every time a file's `status` changes
    const handleChangeStatus = (meta, status) => {
        console.log('status', status)
        if (status === 'done') {
            console.log('status', status, 'meta', meta)
            toBase64(meta.file).then(e => {
                setTalenFile(e)
                setFile(false)
            })
        }
        if (status === 'removed') {
            setTalenFile('')
            setFile(true)
        }
    }
    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        files.map(f => console.log(f))
        allFiles.forEach(f => f.remove())
    }

    const { t } = useTranslation()
    return (
        <div className={contactenosStyle} >
            <Banner
                className='banner'
                text1={t('contact.banner.text1')}
                textColor={t('contact.banner.textColor')}
                text2={t('contact.banner.text2')}
                color={Color.orange}
                parafo={t('contact.banner.parafo')}
            />
            <section className='contContact'>
                <div className='cont'>
                    <div className='text'>
                        {t('contact.contContact.text1')} <br />  {t('contact.contContact.text2')}

                    </div>
                    <div className='contForm' id="contactForm">
                        <div className='text'>
                            Bogotá D.C., Colombia.<br />
                            +57 (1) 750 5456
                        </div>
                        <div className='form'>
                            <input className={`${Input} input`} placeholder={t('contact.form.name')} value={ClientName} onChange={e => setClientName(e.target.value)} />
                            <input className={`${Input} input`} placeholder={t('contact.form.email')} value={ClientMail} onChange={e => setClientMail(e.target.value)} />
                            <input className={`${Input} input`} placeholder={t('contact.form.tel')} value={ClientPhone} onChange={e => setClientPhone(e.target.value)} />
                            <input className={`${Input} input`} placeholder={t('contact.form.company')} value={ClientCompany} onChange={e => setClientCompany(e.target.value)} />
                            <input className={`${Input} input`} placeholder={t('contact.form.message')} value={ClientMesaje} onChange={e => setClientMesaje(e.target.value)} />
                            <button className={`btn hoverOrange ${Btn}  ${BtnTwotext}`} onClick={() => goClientContact()} >
                                <span className="initialText">{t('contact.button.speak')}</span>
                                <span className="textHover">{t('contact.button.readychat')}</span>
                            </button>
                        </div>
                    </div>
                    <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.4182085465095!2d-74.03912854981607!3d4.697176796571477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9a8d2bfc7697%3A0xe4dd691747c06d7!2salun%20ideas!5e0!3m2!1ses!2sco!4v1603593166174!5m2!1ses!2sco" width="100%" height="650" ></iframe>
                    <div className='text textBlock'>
                        {t('contact.map.text1')}
                        <br />
                        {t('contact.map.text2')}
                    </div>
                    <div className='contForm contCand'>
                        <div className='form'>
                            <input className={`${Input} input`} placeholder={t('contact.form2.name')} value={TalenName} onChange={e => setTalenName(e.target.value)} />
                            <input className={`${Input} input`} placeholder={t('contact.form2.email')} value={TalenMail} onChange={e => setTalenMail(e.target.value)} />
                            <input className={`${Input} input`} placeholder={t('contact.form2.phone')} value={TalenPhone} onChange={e => setTalenPhone(e.target.value)} />
                            <div className='checkbox'>
                                <div className='label'>{t('contact.form2.interest')}</div>
                                <Checkbox className='check' animation="pulse" onClick={() => setTalenArea(t('contact.form2.option1'))} checked={t('contact.form2.option1') === TalenArea} >{t('contact.form2.option1')}</Checkbox>
                                <Checkbox className='check' animation="pulse" onClick={() => setTalenArea(t('contact.form2.option2'))} checked={t('contact.form2.option2') === TalenArea} >{t('contact.form2.option2')}</Checkbox>
                                <Checkbox className='check' animation="pulse" onClick={() => setTalenArea(t('contact.form2.option3'))} checked={t('contact.form2.option3') === TalenArea} >{t('contact.form2.option3')}</Checkbox>
                            </div>
                            <input className={`${Input} input`} placeholder={t('contact.form2.message')} value={TalenMensaje} onChange={e => setTalenMensaje(e.target.value)} />
                            <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={handleChangeStatus}
                                onSubmit={handleSubmit}
                                maxFiles={1}
                                multiple={false}
                                inputContent={t('contact.form2.cv')}
                                accept="application/pdf"
                            />
                            <button className={`btn ${Btn}  ${BtnTwotext}`} onClick={() => goTalenContact()} disabled={File} >
                                <span className="initialText">{t('contact.button.areYouReady')}</span>
                                <span className="textHover">{t('contact.button.applyNow')}</span>
                            </button>
                        </div>
                        <img src='https://storage.googleapis.com/storage-alunideas/alunWeb/Secci%C3%B3n%20Contacto/sede-alun.png' />
                    </div>
                </div>
            </section>
            <Galery list={GaleryList} />
        </div>
    )
}

export default Contactenos