import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import i18n from 'i18next'
import { initReactI18next  } from "react-i18next"
//lang
import { resources } from './lang'
// componet
import Aplication from './componet'
// conText
import { MenuProvider } from './context/menu'
import { HomeProvider } from './context/home'
import { GaleryProvider } from './context/galery'
import { LaboratorioProvider } from './context/laboratorio'
import { InnovacionProvider } from './context/innovacion'
import { InfinitoProvider } from './context/infinio'
import { ContactenosProvider } from './context/contactenos'
import { UniRosarioProvider } from './context/uniRosario'
import { CopaTayronaProvider } from './context/copaTayrona'
import { BarcaAcademyProvider } from './context/barcaacademy'


const App = () => {
  i18n.use(initReactI18next).init({
    resources,
    lng:'es',
    fallbackLng: 'es',
    keySeparator: '.',
    interpolaion: {
      escapaValue: false
    }
  })
  return (
    <MenuProvider>
      <HomeProvider>
        <GaleryProvider>
          <LaboratorioProvider>
            <InnovacionProvider>
              <InfinitoProvider>
                <ContactenosProvider>
                  <UniRosarioProvider>
                    <CopaTayronaProvider>
                      <BarcaAcademyProvider>              
                          <Router>
                            <Aplication />
                          </Router>
                      </BarcaAcademyProvider>
                    </CopaTayronaProvider>
                  </UniRosarioProvider>
                </ContactenosProvider>
              </InfinitoProvider>
            </InnovacionProvider>
          </LaboratorioProvider>
        </GaleryProvider>
      </HomeProvider>
    </MenuProvider>
  );
}

export default App;
