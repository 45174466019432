import React from 'react'
import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css'
// funtion
import { valiate } from './funtion/valiate'
// componet
import Message from './items/message'

export const toastMessage = ({message, position, error }) => {
    toast.notify(({onClose}) => (<Message close={onClose} success={error?false:true}>{message}</Message>), { position:position||'bottom-right' })
}

export const validateGlobal = (format, data, {message, position, correctValidate}) => {
    console.log(
        { 
            format, data, message, position
        }
    );

    const correct = correctValidate !== undefined ? correctValidate : true;
        
    let valitate = true;
    
    if(Object.keys(format).length !== Object.keys(data).length){
        valitate = false;
        toast.notify(({onClose})=> (<Message close={onClose}>el formulari esta incompleto</Message>), { position:position||'bottom-right' })
    } else {
        const  res = Object.keys(format).map( key => {
            const valitates = (typeof (format[key])==='string') ? format[key].split("|") : format[key].type.split("|");
            console.log(valitates);
            return ({[key]: valiate(valitates, data[key], format[key].schema || null)});
        });
        Object.keys(format).forEach( key =>{
            const item = res.find(e => e[key]!== undefined)
            if(item[key]===false) valitate = false;
        });
        if(typeof(message)==='object'){
            const messages = Object.keys(message)
            Object.keys(format).forEach( key =>{
                const item = res.find(e => e[key]!== undefined)
                if(item[key]===false){
                    messages.find(e => e ===key)
                   ? toast.notify(({onClose})=> (<Message close={onClose}>{message[key]}</Message>), { position:position||'bottom-right' })
                   : toast.notify(({onClose}) => (<Message close={onClose}>{message.ErrorDefault}</Message>), { position:position||'bottom-right' })
                } 
            })
        }else{
            toast.notify(({onClose}) => (<Message close={onClose}>{message}</Message>), { position:position||'bottom-right' })
        }
    }

    if(valitate && correct) toast.notify(({onClose}) => (<Message close={onClose} success={true}>{typeof(message)==='object'? message.CorrectValidate : ''}</Message>), { position:position||'bottom-right' })

    return [valitate];
}