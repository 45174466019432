/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
// componet
import Banner from '../utils/banner'
import Galery from '../utils/galery'
// context
import { InnovacionContext } from '../../context/innovacion'
// style
import { Color } from '../../style/var'
import { innovacionStyle } from '../../style/innovacion/style'

const Innovacion = () => {
    const {
        GaleryList,
        SkillList
    } = useContext(InnovacionContext)
    const { t } = useTranslation()
    return(
        <div className={innovacionStyle} >
            <Banner
                className='banner'
                text1={t('innovation.banner.text1')}
                textColor={t('innovation.banner.textColor')}
                text2={t('innovation.banner.text2')}
                color={Color.orange}
                parafo={t('innovation.banner.parafo')}
            />
            <section className='contImp'>
                <div className='cont'>
                    <div className='info'>
                        <div className='title'>{t('innovation.contImp.title')}</div>
                    </div>
                </div>
            </section>
            <section className='contSkill'>
                <div className='cont'>
                    <div className='info'>
                        {SkillList.map((skill,i)=> <div key={i} className='skill' >
                            <div className='contDes'>
                                <div className='title'>{t(skill.label)}</div>
                                <div className='des'>{t(skill.des)}</div>
                            </div>
                            <img src={skill.icon} />
                        </div> )}
                    </div>
                    <div className='like'>
                        <img src='https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Innovacio%CC%81n%20_%20Desarrollo/Iconos_Like2.svg' />
                    </div>
                </div>
            </section>
            <div className='blockWhile' ></div>
            <Galery list={GaleryList} />
        </div>
    )
}

export default Innovacion