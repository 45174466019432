/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
// componet
import Banner from '../utils/bannerCase'
import Galery from '../utils/galery'
// context
import { CopaTayronaContext } from '../../context/copaTayrona'
// style
import { copaTayronaStyle } from '../../style/copaTayrona/style'
const CopaTayrona = () => {
    const { GaleryList } = useContext(CopaTayronaContext)
    const { t } = useTranslation()
    return(
        <div className={copaTayronaStyle} >
            <Banner 
                className='banner'
                fondo='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Copa%20Tayrona/herobanner-caso-copa-tayrona.png'
                text= {t('tayrona.banner')}
                img='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Copa%20Tayrona/Grupo%202659.png'
            />
            <section className='contDescrip'>
                <div className='contInfo'>
                    <div className='info'>
                        <div className='cont a'>
                            <div className='title'>{t('tayrona.contInfo.challengeTitle')}</div>
                            <div className='text'>{t('tayrona.contInfo.challengeDescription')}</div>
                        </div>
                        <div className='cont b'>
                            <div className='title'>{t('tayrona.contInfo.solutionTitle')}</div>
                            <div className='text'>{t('tayrona.contInfo.solutionDescription')}</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='contChange' >
                <div className='change' >
                    <div className='cont'>
                        <img src='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Copa%20Tayrona/Grupo%202636.png' />
                        <div className='text'>{t('tayrona.contChange.before')}</div>
                    </div>
                    <FontAwesomeIcon className='icon' icon={faArrowRight} />
                    <div className='cont'>
                        <img src='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Copa%20Tayrona/Grupo%202657.png' />
                        <div className='text'>{t('tayrona.contChange.after')}</div>
                    </div>
                </div>
            </section>
            <section className='contGalery'>
                <div className='cont'>
                    <Galery list={GaleryList} className='galery' />
                </div>
            </section>
            <section className='contElement'>
                <div className='cont'>
                    <div className='title'>{t('tayrona.contElement.title')}</div>
                    <div className='contDes'>
                        <div className='des'>
                            <div className='group'>
                                <div className='title'>{t('tayrona.contElement.koguiTitle')}</div>
                                <div className='text'>
                                    {t('tayrona.contElement.koguiDescription')}
                                </div>
                            </div>
                            <div className='group'>
                                <div className='title'> {t('tayrona.contElement.florTitle')}</div>
                                <div className='text'>
                                    {t('tayrona.contElement.florDescription')}
                                </div>
                            </div>
                        </div>
                        <img src='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Copa%20Tayrona/alun-caso-copa-tayrona-mascaras.gif' />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CopaTayrona