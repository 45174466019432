/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
// style
import { bannerStyle } from '../../../style/utils/banner/style'

const Banner = ({color, logo, text1, textColor, text2, img, parafo, className,imgRes }) => {
    const { t } = useTranslation()
    return (
        <div className={`${bannerStyle} ${className}`} >
            <div className='cont' >
                <div className='info' >
                    {logo && <img className='logo' src={logo} />}
                    <div className='title' >
                        {parse(text1)} <span style={{color}} >{textColor}</span>{parse(text2)}
                    </div>
                    <div className='text' >{parafo?parse(parafo):''}</div>
                </div>
                {img && <div className="imgDesktop">
                    <img src={img} />
                </div>}
                {
                    imgRes && <div className="imgMobile"><img src={imgRes}/></div>
                }
                <div className='parafo' >{parafo?parse(parafo):''}</div>
            </div>
            <img className='git' src='https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/alun-scroll-down.gif' />
        </div>
    )
}

export default Banner