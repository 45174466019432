import { style } from "typestyle"

import { Color, Font } from '../var'

export const marcaStyle = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'AvenirNext',
    $nest: {
        '& .banner': {
            $nest: {
                '& .cont': {
                    $nest: {
                        '& .info': {
                            maxWidth: '720px'
                        }
                    }
                }
            }
        },
        '& .contDescrip': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Color.lightOrange,
            padding: '80px 0px',
            $nest: {
                '& .cont': {
                    width: 'calc(100% - 40px)',
                    maxWidth: '1024px',
                    padding: '20px',
                    display: 'flex',
                    $nest: {
                        '& .des': {
                            width: '65%',
                            fontSize: Font.Normal,
                            fontWeight: 'bold',
                            marginBottom: '50px',
                            '@media screen and (max-width: 600px)': {
                                width: '90%'
                            }
                        }
                    }
                },
                '& .block': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    $nest: {
                        '& .contPhoto': {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            $nest: {
                                '& .a': {
                                    gridColumn: 3,
                                    width: '100%'
                                },
                                '& .b': {
                                    gridColumn: 4,
                                    width: '100%'
                                },
                                '@media screen and (max-width: 890px)': {
                                    width: '100%',
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                    order: 2,
                                    $nest: {
                                        '& .a': {
                                            gridColumn: 'auto',
                                        },
                                        '& .b': {
                                            gridColumn: 'auto',
                                        },
                                    }
                                },
                                '@media screen and (max-width: 500px)': {
                                    gridTemplateColumns: '1fr'
                                }
                            }
                        },
                        '& .contInfo': {
                            width: 'calc(100% - 40px)',
                            maxWidth: '1024px',
                            padding: '20px',
                            display: 'flex',
                            position: 'absolute',
                            $nest: {
                                '& .info': {
                                    width: '45%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    $nest: {
                                        '& .title': {
                                            fontSize: Font.Normal,
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        },
                                        '& .text': {
                                            fontSize: Font.Mini,
                                            fontWeight: 100,
                                            marginBottom: '30px'
                                        },
                                        '@media screen and (max-width: 890px)': {
                                            width: '95%'
                                        },
                                        
                                    }
                                },
                                '@media screen and (max-width: 890px)': {
                                    position: 'relative',
                                    order: 1
                                }
                            }
                        },
                        '@media screen and (max-width: 890px)': {
                            flexWrap: 'wrap'
                        }
                    }
                },
                '@media screen and (max-width: 890px)': {
                    padding: '80px 0px 0px 0px',
                }
            }
        },
        '& .contSob': {
            display: 'flex',
            width: 'calc(100% - 40px)',
            padding: '50px 20px',
            justifyContent: 'center',
            $nest: {
                '& .sod': {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    maxWidth: '1024px',
                    $nest: {
                        '& .text': {
                            color: Color.orange,
                            fontSize: Font.MegaBig,
                            width: '50%',
                            fontWeight: 'bold',
                            $nest: {
                                '@media screen and (max-width: 768px)': {
                                    fontSize: Font.Grande
                                },
                                '@media screen and (max-width: 500px)': {
                                    width: '100%',
                                    fontSize: Font.Title
                                }
                            }
                        },
                        '& img': {
                            width: '200px'
                        }
                    }
                }
            }
        }
    }
})