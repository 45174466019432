import React, {createContext, useState}  from 'react'
const HomeContext = createContext()
const { Provider, Consumer } = HomeContext

const HomeProvider = ({children}) =>  {
    const [TaskFluor, setTaskFluor] = useState([
        {label: 'analisis'},
        {label: 'formulacion'},
        {label: 'desarrollo'},
        {label: 'remoto'},
    ])
    const [ProyectList, setProyectList] = useState([
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-caso-universidad-rosario.png', to:'/caso/uniRosario'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-caso-copa-tayrona.png', to:'/caso/copaTayrona'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-caso-barca-academy.png', to:'/caso/barcaAcademy'},

    ])
    const [BrandItems, setBrandItems] = useState([
        {
            icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/Iconos_Marca.svg',
            title: 'home.agency.items.brand.title' ,
            paragraph: 'home.agency.items.brand.info',
            to: '/marca'
        },
        {
            icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/Iconos_Laboratorio.svg',
            title: 'home.agency.items.laboratory.title' ,
            paragraph: 'home.agency.items.laboratory.info',
            to: '/laboratorio'
        },
        {
            icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/Iconos_Innovacion.svg',
            title: 'home.agency.items.developer.title' ,
            paragraph: 'home.agency.items.developer.info',
            to: '/innovacion'
        },
    ])
    const [Team, setTeam] = useState([
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-santiago.png', icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/emoji-santiago.png' , info: 'Lorem ipsum dolor sit amet consectetur adipiscing elit, cubilia nisi fermentum malesuada nullam pharetra, aliquet accumsan taciti hac potenti dis.', link: 'https://www.linkedin.com/in/santiago-amortegui-1bb2a114/' },
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-claudia.png' , icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/emoji-claudia.png' , info: 'Lorem ipsum dolor sit amet consectetur adipiscing elit, cubilia nisi fermentum malesuada nullam pharetra, aliquet accumsan taciti hac potenti dis.', link: 'https://www.linkedin.com/in/claudia-fuerte-021105a2/'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-pilar.png' , icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/emoji-pilar.png' , info: 'Lorem ipsum dolor sit amet consectetur adipiscing elit, cubilia nisi fermentum malesuada nullam pharetra, aliquet accumsan taciti hac potenti dis.', link: 'https://www.linkedin.com/in/pilar-torres-1166944a/'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-danielm.png', icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/emoji-danielm.png' , info: 'Lorem ipsum dolor sit amet consectetur adipiscing elit, cubilia nisi fermentum malesuada nullam pharetra, aliquet accumsan taciti hac potenti dis.', link: 'https://www.linkedin.com/in/itsdamego/' },
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-jorge.png', icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/emoji-jorge.png' , info: 'Lorem ipsum dolor sit amet consectetur adipiscing elit, cubilia nisi fermentum malesuada nullam pharetra, aliquet accumsan taciti hac potenti dis.', link: 'https://www.linkedin.com/in/jorge-stiven-m-6a500b116/' },
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-jessica.png', icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/emoji-jessica.png' , info: 'Lorem ipsum dolor sit amet consectetur adipiscing elit, cubilia nisi fermentum malesuada nullam pharetra, aliquet accumsan taciti hac potenti dis.', link: 'https://www.linkedin.com/in/jessica-sandoval-estupi%C3%B1an-509938178/' },
    ])
    const [CompanysInfo, setCompanysInfo] = useState([
        {
            title:'home.companys.items.infinito.title',
            des:'home.companys.items.infinito.description',
            img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-infinito.png',
            to:'/infinito'
        },
        {
            title:'home.companys.items.greater.title',
            des:'home.companys.items.greater.description',
            img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-greater-bogota.png',
            to:'/'
        }
    ])
    const [LogoCompany, setLogoCompany] = useState([
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-fronterasky.png', to:'/'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-calatrava-school.png', to:'/'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-masterbeer.png', to:'/'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-meaning-corp.png', to:'/'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-saenz-fety.png', to:'/'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-barca-academy.png', to:'/caso/barcaAcademy'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-copa-tayrona.png', to: '/caso/copaTayrona'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-unikron.png', to:'/'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-usa-scouts.png', to:'/'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-matrix-international.png', to:'/'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-universidad-rosario.png', to: '/caso/uniRosario'},
        {img:'https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-logo-colsubsidio.png', to:'/'}
    ])
    const [ViewModal, setViewModal] = useState(null)
    const [Name,setName] = useState('')
    const [Email, setEmail] = useState('')
    const [Message, setMessage] = useState('')
    return(
        <Provider
            value={{
                TaskFluor, setTaskFluor,
                ProyectList, setProyectList,
                BrandItems, setBrandItems,
                Team, setTeam,
                CompanysInfo, setCompanysInfo,
                LogoCompany, setLogoCompany,
                ViewModal, setViewModal,
                Name,setName,
                Email, setEmail,
                Message, setMessage
            }}
        >
           {children}
        </Provider>
    )
}

export { HomeProvider, Consumer as HomeConsumer, HomeContext }