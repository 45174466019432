import React from 'react'
import {
  Switch,
  Route,
} from 'react-router-dom'
// component
import Home from '../home'
import Marca from '../marca'
import Laboratorio from '../laboratorio'
import Innovacion from '../innovacion'
/* import Infinito from '../infinito' */
import Contactenos from '../contactenos'
import UniRosario from '../uniRosario'
import CopaTayrona from '../copaTayrona'
import BarcaAcademy from '../barcaAcademy'
const Menu = () => {
  return (
    <Switch >
      <Route exact path="/"><Home /></Route>
      <Route path="/marca"><Marca /></Route>
      <Route path="/laboratorio"><Laboratorio /></Route>
      <Route path="/innovacion"><Innovacion /></Route>
      {/* <Route path="/infinito"><Infinito /></Route> */}
      <Route path="/contactenos"><Contactenos /></Route>
      <Route exact path="/caso/uniRosario"><UniRosario /></Route>
      <Route exact path="/caso/copaTayrona"><CopaTayrona /></Route>
      <Route exact path="/caso/barcaAcademy"><BarcaAcademy /></Route>
    </Switch>
  );
}

export default Menu;
