export const en = {
    title: 'Alun Ideas',
    menu: {
        marca: 'Brand',
        laboratorio: 'Digital Laboratory',
        innovacion: 'Innovation & Development',
        contactenos: 'Contact Us',
        infinito: 'Infinito',
        mialun: 'My Alün',
        lang: {
            es: 'ES',
            en: 'EN'
        }
    },
    footer: {
        contactenos: {
            title: 'Contact Us'
        },
        descubranos: {
            title: 'Discover us',
            text: 'Work together',
            text2: 'Employment exchange'
        },
        partners: {
            title: 'Partners',
            infinito: 'INFINITO',
            greater: 'Greater Bogotá Convention Bureau'
        },
        aviso: 'Notice of Privacy',
        politicas: 'Data policy'
    },
    home: {
        banner: {
            title: {
                text1: 'Hello! we are',
                textColor: 'Alün Ideas',
                text2: 'the storytelling house.',
            },
            parafo: 'The 3 pillars that underpin our developments are: commitment to the customer, innovation in processes to generate surprising results and absolute attention to to the values ​​of the brands.'
        },
        task: {
            phrase: 'Much more than you already know',
            subtitle: 'We create strategies that respond to the needs of our clients.',
            paragraph: 'With our own creative model that originates from storytelling, we want to tell the story that wants to be heard by its target audience.',
            btn: 'I want to know more about Alün Ideas',
            btnHover: 'Let´s work together!',
            items: {
                analisis: 'Analysis and research',
                formulacion: 'Strategy formulation',
                desarrollo: 'Innovation and Development',
                remoto: 'Return on investment (ROI)',
            },
            title: {
                text: 'We also believe in innovation',
                text2: 'and in the results.'
            }
        },
        projects: {
            phrase: 'These are some of the projects we have developed for clients, who are now friends from home.',
            text: 'But you can also take a look at what else we have done.',
            btn: 'I want to see more',
            btnHoverForm: 'You´ll be surprised!!',
            btnHover: '¡Work Together!'
        },
        agency: {
            phrase: 'Made with love by alunideas.com',
            title: 'We are a full service agency, but this is what we do best.',
            items: {
                brand: {
                    title: 'Brand',
                    info: 'We create strategies that manage to communicate the essence of each brand so that they project what is really important to their audience. Your brand should stand out, but providing clarity with what you are offering to the market.'
                },
                laboratory: {
                    title: 'Digital Laboratory',
                    info: 'We believe in digital growth as a direct communication channel where good strategies put the game in your favor. We work with results and to achieve them we are faithful to excessive planning, projected in the short, medium and long term.'
                },
                developer: {
                    title: 'Development & Innovation',
                    info: 'Our goal is to implement the digital tools necessary to achieve the KPI’s set out according to your brand plan, we rely on new technologies and development of new formats to have the necessary elements to meet each objective.'
                }

            }
        },
        team: {
            subtitle: 'We believe in people with immense spirits',
            title: 'This is our team of creators.'
        },
        companys: {
            subtitle: 'We like good company',
            title: 'We are part of great ideals',
            items: {
                infinito: {
                    title: 'Infinito Consultants',
                    description: 'Branding consultancy created in 2005, focused on helping to transform brands and businesses with solutions that combine the clarity of strategic thinking and the audacity of original ideas, expressed in a design of the highest standard.'
                },
                greater: {
                    title: 'Greater Bogotá Convention Bureau',
                    description: 'It is a non-profit entity that aims to lead the promotion strategy of Bogotá as a venue for international events, such as congresses, meetings and business incentives.'
                }
            }
        }
    },
    brand: {
        banner: {
            text1: 'The first',
            textColor: 'impression',
            text2: ', is what your customers will remember.',
            paragraph: 'Your brand should look good on any platform: digital channels, print and in all the spaces of which it is part. In this way, you will communicate and position yourself efficiently in the minds of your consumers.'
        },
        contDescrip: {
            des: 'At Alün Ideas we know that brands go beyond visual differentiation, they must effectively represent values ​​and attributes, to transform the brand idea into a social experience.',
            info: {
                title: 'Graphic branding strategy',
                text: 'Everything that surrounds your visual identity must be unique and fit with what you want to communicate.',
                consultancyTitle: 'Brand consulting for traditional and digital platforms',
                consultancyText: 'We answer the question of the real status of your brand, differentiating by types of communication channels.',
                eventsTitle: 'Identifier for events',
                eventsText: 'An activity with exclusive dates needs to be positioned, and the best way is with an identifier that communicates the essence of the experience.'
            },
            contSob: {
                text: 'Your brand must be unique, able to stand out.'
            }
        }
    },
    laboratory: {
        banner: {
            text1: ' Working with specific KPIs is the best ',
            textColor: 'strategy.',
            text2: '',
            parafo: 'Our management of digital channels is focused on commercial KPIs with a lot of creativity. Having clear objectives allows you to optimize the platforms to promote and position your brand in a more efficient way.'
        },
        contImp: {
            title: 'Boost your brand on the right platforms.',
            description: 'We believe in digital platforms as a direct communication channel, where good strategies puts the game in your favor. We work with results based on the planning projected in the short, medium and long term.'
        },
        skills: {
            social: {
                label: 'Social Media',
                description: 'Consulting for the development of a content plan in social networks, focused on the growth and strengthening of digital communities.'
            },
            marketing: {
                label: 'Digital marketing',
                description: 'Campaign planning on digital platforms, to obtain commercial results.',
            },
            seo: {
                label: 'SEO / SEM',
                description: 'Effective positioning in web search engines, generating organic and advertising content..'
            },
            inBound: {
                label: 'InBound - OutBound marketing',
                description: 'Optimization of resources to attract customers, with strategies for the conversion of commercial objectives.'
            }
        }
    },
    innovation: {
        banner: {
            text1: 'Find out how  ',
            textColor: 'technology',
            text2: ' can be your best ally.',
            parafo: "We rely on new technologies and the development of new formats, to meet each of our clients' digital objectives. <br/> <br/>We can start a project from scratch or resume one that is paused. Our goal is to ensure that the user experience is what your brand wants and must convey."
        },
        contImp: {
            title: 'Our services respond to your needs or those of your client.'
        },
        skills: {
            webDev: {
                label: 'Web design and development',
                description: 'We create websites according to the specific requirements of our clients, where user experience is the main communication factor.'
            },
            appDev: {
                label: 'Design and development of mobile applications',
                description: 'Creation of mobile applications, meeting corporate and commercial requirements, ideal tools for your brand.'
            },
            webApp: {
                label: 'Design and development of web apps',
                description: 'Creation of applications hosted on websites for temporary events, a digital experience that will enhance your brand.'
            }
        }
    },
    contact: {
        banner: {
            text1: "Let's talk and discover everything we have to ",
            textColor: 'offer.',
            text2: '',
            parafo: 'At Alün Ideas we connect brands with their audiences through a creative model, in which technological development, innovation, consulting and strategy are fundamental axes to achieve effective results.'
        },
        contContact: {
            text1: "Contact us and let's start",
            text2: 'plan your strategy.'
        },
        form: {
            name: 'Full name*',
            email: 'E-mail*',
            tel: 'Telephone contact*',
            company: 'Company',
            message: 'Message*',
            message2:'What are you looking for?',
            download:'Download'

        },
        map: {
            text1: 'We are constantly looking for talents,',
            text2: ' we want to know which one is yours, send us your CV.'
        },
        form2: {
            name: 'Full name*',
            email: 'E-mail*',
            phone: 'Telephone contact*',
            interest: 'Area of ​​interest*',
            option1: 'Design',
            option2: 'Accounts',
            option3: 'Developing',
            message: 'Message*',
            cv: 'Attach your CV*'
        },
        button: {
            speak: "Send",
            readychat: "We want to surprise you!",
            areYouReady: 'Send',
            applyNow: "Let's see your potential"
        },
        message: {
            name: 'The name is required',
            mail: 'The email is not valid',
            phone: 'The phone is required',
            mesaje: 'The message is required',
            area: 'The area of interest is required',
            file: 'Attach your CV',
            errorDefault: 'Oops something went wrong ...',
            correctValidate: 'We will be in touch very soon, Thank you for having us',
            correctMessageHome: 'Thank you for be interesed in our job'
        }
    },
    infinito: {
        banner: {
            text1: '',
            textColor: 'Branding',
            text2: ' is <br class="br"/> a management tool.',
            parafo: "Coinciding with the largest Branding Consultant in Peru as a strategic ally to increase the value of our agency's service is an achievement that makes us proud."
        },
        contInfo: {
            text1: 'Our goal will always be to offer the best products to our clients, that is why we achieved a strategic alliance with INFINITO, the largest branding consultancy in Peru.',
            text2: "With the union of our integral services in digital communication and INFINITO's Branding services, we created a powerful alliance based on brand development."
        },
        skill: {
            brand: {
                title: 'Brand Identity',
                description: 'Every great brand must have a reason for being: for its collaborators, for its customers and for society. We help brands to find and express it through an impressive and inspiring identity that generates value for companies.'
            },
            packaging: {
                title: 'Packaging Design',
                description: 'We develop attractive and innovative packaging that connects emotionally with consumers from a deep understanding of the market and the category.'
            },
            retail: {
                title: 'Retail Design',
                description: 'From corporate headquarters to restaurants and commercial stores, we help brands revitalize their experiences with consumers and reinvent the role that Retail Branding plays in their business strategy.'
            },
            digital: {
                title: 'Digital Communication',
                description: 'The digital presence has become the presence in the real world. We create digital content strategies, we use innovative formats, we know the latest trends and we execute them through the highest design standard.'
            }
        },
        form: {
            text: 'We create brands with infinite potential, contact us.',
            name: 'Full name*',
            email: 'E-mail*',
            tel: 'Telephone contact*',
            company: 'Company',
            message: 'Message*',
            button: {
                send: 'Send',
                weSend: 'We will contact you soon'
            }
        },
        message: {
            name: 'The name is required',
            mail: 'The email is not valid',
            phone: 'The phone is required',
            mesaje: 'The message is required',
            area: 'The area of interest is required',
            file: 'Attach your CV',
            errorDefault: 'Oops something went wrong ...',
            correctValidate: 'We will be in touch very soon, Thank you for having us'
        }
    },
    rosario: {
        banner: 'Digital tool for attendance and access control',
        contInfo: {
            challengeTitle: 'Our challenge',
            challengeDescription: 'Develop and implement a digital tool that would allow the Universidad del Rosario to have real-time control of its events with the student community. This, with the purpose of minimizing logistical and administrative expenses in their activities, while being able to control their execution.',
            solutionTitle: 'Our solution',
            solutionDescription: 'We adapt to the specific needs of the Universidad del Rosario.<br/> Express Registration, is our mobile platform registration system that just by having a cell phone or tablet of any operating system allows event organizers to monitor and control access to their activity during the event.'
        },
        contSob: {
            title: 'What did we achieve',
            item1: 'Optimize and intelligently manage your databases.',
            item2: 'Carry out the mass sending of emails for the coordination of activities.',
            item3: 'Keep an updated control of operations and logistics management by event.',
            item4: 'Improve the experience of access to events for the population with cognitive or physical disabilities.',
            item5: 'Digitize student access through a QR code, allowing entry per event to be in less than a minute.'
        },
    },
    tayrona: {
        banner: 'The most important glass of craft beer in the country is the Tayrona.',
        contInfo: {
            challengeTitle: 'Our challenge',
            challengeDescription: 'The Tayrona Cup is a competition dedicated to the judging of 100% craft beers in Latin America. With the intention of positioning itself in the region, after becoming a member of the League of Beer Masters, it sought to renew its visual identity under three ideals: tradition, maximum respect for ingredients and quality.',
            solutionTitle: 'Our solution',
            solutionDescription: 'We create a brand strategy based on the craftsmanship and dedicated work of the goldsmiths and ceramics of the Amerindian culture native to the region. With its representative elements, we created a graphic brand that is easy to remember, which highlights the culture of the place where the glass is located and the main ingredient of a good independent beer, hops.'
        },
        contChange: {
            before: 'Before',
            after: 'Now'
        },
        contElement: {
            title: 'Key elements that were used for the development of the graphic identity of Copa Tayrona.',
            koguiTitle: 'Ceremonial mask of the Kogui community',
            koguiDescription: 'This community uses masks in their ceremonies and rituals of greater significance and relevance. Tayrona is the most important independent beer cup in the country, due to the quality of its judges, the scope of the call and its link with the most important Latin American league of craft beers.',
            florTitle: 'The hop flower as inspiration',
            florDescription: 'Hops is the primary ingredient for all beer, it is used by each brewmaster as the secret ingredient that makes the difference in each creation.'
        }
    },
    barca: {
        banner: 'Training through sport with the FC Barcelona methodology.',
        contInfo: {
            challengeTitle: 'Our challenge',
            challengeDescription: 'Attract new students for all the campuses in Colombia, promote the Barça Academy Camps each season and increase the digital community by continuously maintaining its followers and stakeholders.'
        },
        requirements: {
            title: 'The requirements and objectives were:',
            item1: 'Generate qualified leads interested in being part of the academy.',
            item2: 'Promote the IV Barça Academy Las Américas Cup.',
            item3: "Manage the community in social networks and PQR's attention.",
            item4: 'Increase interactions and grow the community with parents interested in enrolling their children in the academy.',
            item5: 'Barça Academy digital positioning.'
        },
        strategy: {
            title: 'Our strategy',
            item1: 'Creation of a web design and development renovation plan, in which all the information hosted on the previous page was implemented, with improvements in design, text production and digital image renovation.',
            item2: 'Linking the website with the Alün Ideas CRM to administer and manage the leads obtained.',
            item3: 'Construction of an organic content plan and guideline for Google Ads and Facebook Ads platforms with monthly distribution.',
            
        }
    },
    modal:{
        text: 'Thank you for your interest in our work!'
    }
}