import React, {createContext, useState}  from 'react'
const InnovacionContext = createContext()
const { Provider, Consumer } = InnovacionContext

const InnovacionProvider = ({children}) =>  {
    const [GaleryList, setGaleryList] = useState([
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Innovacio%CC%81n%20_%20Desarrollo/alun-blossomdistrict-desarrollo-web.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Innovacio%CC%81n%20_%20Desarrollo/alun-masterbeer-marca.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Innovacio%CC%81n%20_%20Desarrollo/alun-blossomdistrict-redes-sociales.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Innovacio%CC%81n%20_%20Desarrollo/alun-barcaacademy-redes-sociales.png', to: '/caso/barcaAcademy'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Innovacio%CC%81n%20_%20Desarrollo/alun-cartagenainspira-desarrollo-videojuegos.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Innovacio%CC%81n%20_%20Desarrollo/alun-fronterasky-marca.png'}
    ])
    const [SkillList, setSkillList] = useState([
        {icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/Iconos_Creacion%20web2.svg',
        label: 'innovation.skills.webDev.label',
        des: 'innovation.skills.webDev.description'},
        {icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/Iconos_Apps2.svg', 
        label:'innovation.skills.appDev.label',
        des: 'innovation.skills.appDev.description'},
        {icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Iconos/icono_web-apps.svg', 
        label: 'innovation.skills.webApp.label', 
        des: 'innovation.skills.webApp.description'},
    ])
    return(
        <Provider
            value={{
                GaleryList, setGaleryList,
                SkillList, setSkillList
            }}
        >
           {children} 
        </Provider>
    )
}

export { InnovacionProvider, Consumer as InnovacionConsumer, InnovacionContext }