/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
// componet
import Banner from '../utils/banner'
import Galery from '../utils/galery'
// context
import { LaboratorioContext } from '../../context/laboratorio'
// style
import { Color } from '../../style/var'
import { laboratorioStyle } from '../../style/laboratorio/style'

const Laboratorio = () => {
    const { 
        GaleryList,
        SkillList 
    } = useContext(LaboratorioContext)
    const { t } = useTranslation()
    return(
        <div className={laboratorioStyle} >
            <Banner 
                className='banner'
                text1={t('laboratory.banner.text1')}
                textColor={t('laboratory.banner.textColor')}
                text2={t('laboratory.banner.text2')}
                color={Color.orange}
                parafo={t('laboratory.banner.parafo')}
            />
            <section className='contImp'>
                <div className='cont'>
                    <div className='info'>
                        <div className='title'>{t('laboratory.contImp.title')}</div>
                        <div className='des'>{t('laboratory.contImp.description')}</div>
                    </div>
                    <img src='https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/Iconos_RRSS2.svg' />
                </div>
            </section>
            <section className='contSkill'>
                <div className='cont'>
                    {SkillList.map((skill,i)=> <div key={i} className='skill' >
                        <img src={skill.icon} />
                        <div className='title'>{t(skill.label)}</div>
                        <div className='des'>{t(skill.des)}</div>
                    </div> )}
                </div>
            </section>
            <Galery list={GaleryList} />
        </div>
    )
}

export default Laboratorio