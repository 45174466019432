import { style } from "typestyle"

import { Color, Font } from '../var'

export const uniRosarioStyle = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'AvenirNext',
    $nest: {
        '& .banner': {
            $nest: {
               '& .cont': {
                   $nest: {
                        '& .info': {
                            maxWidth: '620px'
                        }
                   }
               } 
            }
        },
        '& .contDescrip': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Color.white,
            padding: '0px',
            $nest: {
                '& .block': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    $nest: {
                        '& .contPhoto': {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            $nest: {
                                '& .a': {
                                    gridColumn: 2,
                                    width: '100%'
                                },
                                '@media screen and (max-width: 940px)': {
                                    gridTemplateColumns: '1fr',
                                    order: 2
                                }
                            }
                        },
                        '& .contInfo': {
                            width: 'calc(100% - 40px)',
                            maxWidth: '1024px',
                            padding: '20px',
                            display: 'flex',
                            position: 'absolute',
                            $nest: {
                                '& .info': {
                                    width: '45%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    $nest: {
                                        '& .title': {
                                            fontSize: Font.Normal,
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        },
                                        '& .text': {
                                            fontSize: Font.Mini,
                                            fontWeight: 100,
                                            marginBottom: '30px'
                                        },
                                        '@media screen and (max-width: 940px)': {
                                            width: '100%'
                                        }
                                    }
                                },
                                '@media screen and (max-width: 940px)': {
                                    position: 'relative',
                                    order: 1
                                }
                            }
                        },
                        '@media screen and (max-width: 9400px)': {
                            flexWrap: 'wrap'
                        }
                    }
                }
            }
        },
        '& .contSob': {
            display: 'flex',
            width: 'calc(100% - 40px)',
            padding: '50px 20px',
            justifyContent: 'center',
            backgroundColor: Color.lightOrange,
            $nest: {
                '& .sod': {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    maxWidth: '1024px',
                    $nest: {
                        '& .text': {
                            color: Color.black,
                            fontSize: Font.Mediun,
                            width: '50%',
                            fontWeight: 'bold',
                            $nest: {
                                '@media screen and (max-width: 500px)': {
                                    width: '100%'
                                }
                            }
                        },
                        '& ul':{
                            paddingLeft: '15px',
                            fontSize: Font.Mini,
                            fontWeight: 100
                        }
                    }
                }
            }
        },
        '& .contGalery': {
            width: 'calc(100% - 40px)',
            padding: '0px 20px',
            display: 'flex',
            justifyContent: 'center',
            $nest: {
                '& .cont':{
                    width: '100%',
                    maxWidth: '1024px'
                },
                '@media screen and (max-width: 1024px)': {
                    width: '100%',
                    padding: '0px',
                }
            }
        }
    }
})