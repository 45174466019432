import React, {createContext, useState}  from 'react'
const UniRosarioContext = createContext()
const { Provider, Consumer } = UniRosarioContext

const UniRosarioProvider = ({children}) =>  {
    const [GaleryList, setGaleryList] = useState([
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Universidad%20del%20Rosario/alun-caso-universidad-rosario1.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Universidad%20del%20Rosario/alun-caso-universidad-rosario5.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Universidad%20del%20Rosario/alun-caso-universidad-rosario3.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Universidad%20del%20Rosario/alun-caso-universidad-rosario6.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Universidad%20del%20Rosario/alun-caso-universidad-rosario2.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Universidad%20del%20Rosario/alun-caso-universidad-rosario7.png'},
    ])
    return(
        <Provider
            value={{
                GaleryList, setGaleryList
            }}
        >
           {children} 
        </Provider>
    )
}

export { UniRosarioProvider, Consumer as UniRosarioConsumer, UniRosarioContext }