import { style } from "typestyle"

import { Color, Font } from '../var'

export const homeStyle = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'AvenirNext',
    $nest: {
        '& .task': {
            width: 'calc(100% - 40px)',
            backgroundColor: Color.orange,
            padding: '50px 20px',
            display: 'flex',
            justifyContent: 'center',
            $nest: {
                '& .cont': {
                    width: '100%',
                    display: 'flex',
                    maxWidth: '1024px',
                    flexDirection: 'column',
                    $nest: {
                        '& .info': {
                            width: '45%',
                            display: 'flex',
                            flexDirection: 'column',
                            color: Color.white,
                            $nest: {
                                '@media screen and (max-width: 768px)': {
                                    width: '100%',
                                },
                                '& .phrase': {
                                    fontSize: Font.Mini,
                                    marginBottom: '15px'
                                },
                                '& .subtitle': {
                                    fontSize: Font.Mediun,
                                    fontWeight: 'bold',
                                    marginBottom: '15px'
                                },
                                '& .paragraph': {
                                    fontSize: Font.Normal,
                                    fontWeight: '100',
                                    marginBottom: '15px'
                                },
                                '& .btn': {
                                    marginBottom: '15px',
                                    $nest: {
                                        '& .initialText': {
                                            display: 'block'
                                        }
                                    }
                                },
                                '& .button' :{
                                    '@media screen and (max-width: 600px)': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: '20px'
                                    }
                                }
                            }
                        },
                        '& .fluor': {
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            padding: '50px 0px',
                            alignItems: 'center',
                            $nest: {
                                '& .circul': {
                                    borderColor: Color.white,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderRadius: '50%',
                                    width: 'calc(170px - 40px)',
                                    height: 'calc(170px - 40px)',
                                    display: 'flex',
                                    color: Color.white,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 100,
                                    fontSize: Font.Mediun,
                                    padding: '20px',
                                    textAlign: 'center',
                                    $nest: {
                                        '&:hover': {
                                            backgroundColor: Color.white,
                                            color: Color.black,
                                            borderColor: Color.black,
                                            transform: 'scale(1.15)',
                                            cursor: 'default',
                                            transitionDuration: '0.5s'
                                        },
                                        '@media screen and (max-width: 604px)': {
                                            width: 'calc(120px - 40px)',
                                            height: 'calc(120px - 40px)',
                                            fontSize: '14px',
                                            marginTop: '10px'
                                        }
                                    }
                                },
                                '& .contLine': {
                                    flex: '1 1 auto',
                                    margin: '15px',
                                    $nest: {
                                        '& .line': {
                                            borderTopColor: Color.white,
                                            borderTopWidth: '1px',
                                            borderTopStyle: 'solid',
                                        },
                                        '@media screen and (max-width: 860px)': {
                                            margin: '20px',
                                            $nest: {
                                                '&.router': {
                                                    flex: '1 1 100%',
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    $nest: {
                                                        '& .line': {
                                                            width: '50%',
                                                            transform: 'rotate(-15deg)'
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        '@media screen and (max-width: 460px)': {
                                            flex: '1 1 auto',
                                            margin: '20px 5px',
                                            $nest: {
                                                '&.router': {
                                                    $nest: {
                                                        '& .line': {
                                                            width: '30%',
                                                            transform: 'rotate(-30deg)'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '& .title': {
                        fontSize: Font.Title,
                        fontWeight: 'bold',
                        color: Color.white,
                        '@media screen and (max-width: 600px)': {
                            fontSize: Font.Mediun,
                            width:'75%',
                            '& .jump': {
                                display: 'none'
                            }
                        },

                    }
                }
            }
        },
        '& .proyect': {
            backgroundColor: Color.lightOrange,
            width: 'calc(100% - 40px)',
            padding: '150px 20px',
            display: 'flex',
            justifyContent: 'center',
            $nest: {
                '& .cont': {
                    width: '100%',
                    display: 'flex',
                    maxWidth: '1024px',
                    flexDirection: 'column',
                    $nest: {
                        '& .title': {
                            width: '54%',
                            fontWeight: 'bold',
                            marginBottom: '50px',
                            fontSize: Font.Normal,
                            $nest: {
                                '@media screen and (max-width: 768px)': {
                                    width: 'calc(100% - 40px)',
                                    padding: '0px 20px'
                                }
                            }
                        },
                        '& .proyects': {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            gridGap: '20px',
                            $nest: {
                                '& .contImg': {
                                    $nest: {
                                        '& img': {
                                            width: '100%'
                                        }
                                    }
                                },
                                '@media screen and (max-width: 650px)': {
                                    gridTemplateColumns: '1fr'
                                },
                            }
                        },
                        '& .contBtn': {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: '50px',
                            $nest: {
                                '& .info': {
                                    width: '25%',
                                    marginRight: '20px',
                                    textAlign: 'right',
                                    fontSize: Font.Mini,
                                    $nest: {
                                        '@media screen and (max-width: 768px)': {
                                            width: 'calc(100% - 40px)',
                                            textAlign: 'left',
                                            marginBottom: '30px',
                                            marginRight: '0px',
                                        },
                                    }
                                },
                                '& .btn': {
                                    border: '1px solid ',
                                },
                                '& .button' :{
                                    '@media screen and (max-width: 600px)': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                    },
                                },
                                '@media screen and (max-width: 768px)': {
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start',
                                    width: 'calc(100% - 40px)',
                                    marginTop: '70px',
                                    padding: '0px 20px'
                                },
                            
                        
                            }
                        }
                    }
                },
                '@media screen and (max-width: 768px)': {
                    width: '100%',
                    padding: '70px 0px'
                }
            }
        },
        '& .agency': {
            backgroundColor: Color.lightBlack,
            width: 'calc(100% - 40px)',
            padding: '80px 20px',
            display: 'flex',
            justifyContent: 'center',
            $nest: {
                '& .cont': {
                    width: '100%',
                    display: 'flex',
                    maxWidth: '1024px',
                    flexDirection: 'column',
                    $nest: {
                        '& .phrase': {
                            color: Color.white,
                            fontSize: Font.Mini,
                            marginBottom: '30px',
                            fontWeight: 100
                        },
                        '& .title': {
                            color: Color.orange,
                            fontSize: Font.Mediun,
                            marginBottom: '30px',
                            fontWeight: 'bold',
                            width: '45%',
                            '@media screen and (max-width: 650px)': {
                                width: '100%',
                            }
                        },
                        '& .brand': {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '50px',
                            $nest: {
                                '& .info': {
                                    color: Color.white,
                                    width: '50%',
                                    $nest: {
                                        '& a': {
                                            textDecoration: 'none',
                                            $nest: {
                                                '&:hover': {
                                                    textDecoration: 'underline #FF5000'

                                                }
                                            }
                                        },
                                        '& .title': {
                                            color: Color.white,
                                            fontWeight: 'normal',
                                            fontSize: Font.Normal,
                                            $nest: {
                                                '&:hover': {
                                                    color: Color.orange,
                                                }
                                            }
                                        },
                                        '& .paragraph': {
                                            fontWeight: 100,
                                            fontSize: Font.Mini
                                        },
                                        '@media screen and (max-width: 650px)': {
                                            width: '100%',
                                        },
                                    }
                                },
                                '& .line': {
                                    flex: '1 1 auto',
                                    borderTopColor: Color.white,
                                    borderTopWidth: '1px',
                                    borderTopStyle: 'solid',
                                    margin: '10px 20px',
                                    $nest: {
                                        '@media screen and (max-width: 650px)': {
                                            display: 'none'
                                        }
                                    }
                                },
                                '& .icon': {
                                    width: '170px',
                                    $nest: {
                                        '@media screen and (max-width: 650px)': {
                                            display: 'none'
                                        },
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        '& .team': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            order: 1,
            $nest: {
                '& .contTitle': {
                    backgroundColor: Color.white,
                    width: 'calc(100% - 40px)',
                    padding: '50px 20px',
                    display: 'flex',
                    justifyContent: 'center',
                    $nest: {
                        '& .cont': {
                            width: '100%',
                            maxWidth: '1024px',
                            $nest: {
                                '& .subtitle': {
                                    fontSize: Font.Mini,
                                    fontWeight: 'bold',
                                    marginBottom: '15px',
                                    '@media screen and (max-width: 600px)': {
                                        width: '60%'
                                    },
                                    '@media screen and (max-width: 360px)': {
                                        width: '70%'
                                    }
                                },
                                '& .title': {
                                    fontSize: Font.Normal,
                                    fontWeight: 'bold',
                                    color: Color.orange,
                                    marginBottom: '50px',
                                    '@media screen and (max-width: 600px)': {
                                        width: '60%',
                                        marginBottom: '0px',
                                    },
                                    '@media screen and (max-width: 360px)': {
                                        width: '70%'
                                    }
                                }
                            }
                        },
                        '@media screen and (max-width: 600px)': {
                            padding: '20px',
                        }
                    }
                },
                '& .contPhone': {
                    display: 'grid',
                    backgroundColor: Color.lightWhite,
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gridGap: '5px',
                    position: 'relative',
                    textAlign: 'center',
                    $nest: {
                        '& .phone': {
                            width: '100%',
                            display: 'grid',
                            position: 'relative',
                            overflow: 'hidden',
                            $nest: {
                                '& .img': {
                                    width: '100%'
                                },
                            }
                        },
                        '& .description': {
                            position: "absolute",
                            overflow: 'hidden',
                            display: 'none',
                            top: '0',
                            zIndex: '2',
                            margin: '0', 
                            width: '100%',
                            color: 'rgba(255,80,0, 0.9)',
                            height: '100%',
                            background: 'rgba(255,80,0, 0.9)',
                            fontSize: Font.micro ,
                            fontWeight: 100,
                            $nest: {
                                '& .text': {
                                    padding: '20px',
                                    visibility: "hidden",
                                },
                                '& .linkedin': {
                                    fontSize: Font.Mediun,
                                    color: Color.white,
                                },
                                '@media screen and (max-width: 600px)': {
                                    height: '100%',
                                }
                            }
                        },
                   /*      '& :hover': {
                            '& .description':{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }
                        }, */
                        '@media screen and (max-width: 768px)': {
                            gridTemplateColumns: 'repeat(3, 1fr)',
                        },
                        '@media screen and (max-width: 425px)': {
                            gridTemplateColumns: '1fr',
                            gridGap: '0px',
                        }
                    }
                },

                '& .contSocial': {
                    backgroundColor: Color.lightWhite,
                    width: 'calc(100% - 40px)',
                    padding: '50px 20px',
                    display: 'flex',
                    justifyContent: 'center',
                    $nest: {
                        '& .cont': {
                            width: '200px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            $nest: {
                                '& .red': {
                                    fontSize: Font.Mediun,
                                    color: Color.lightGrey,
                                    $nest: {
                                        '&:hover': {
                                            color: Color.orange
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                '@media screen and (max-width: 768px)': {
                    order: 2
                }
            }
        },
        '& .companys': {
            width: 'calc(100% - 40px)',
            padding: '50px 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            order: 2,
            $nest: {
                'a': {
                    textDecoration: 'none',
                    $nest: {
                        '&:visited': {
                            color: Color.blue
                        }
                    }
                },
                '& .cont': {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    maxWidth: '1024px',
                    $nest: {
                        '& .contTitle': {
                            width: '100%',
                            $nest: {
                                '& .subtitle': {
                                    fontSize: Font.Mini,
                                    fontWeight: 'bold',
                                    marginBottom: '15px'
                                },
                                '& .title': {
                                    fontSize: Font.Normal,
                                    fontWeight: 'bold',
                                    color: Color.orange,
                                    marginBottom: '50px',
                                    '@media screen and (max-width: 600px)': {
                                        width: '80%'
                                    }
                                }
                            }
                        },
                        '& .company': {
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',

                            $nest: {
                                '& .cont': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: '50px',
                                    alignItems: 'center',
                                    $nest: {
                                        '& .info': {
                                            width: '50%',
                                            marginRight: '50px',
                                            $nest: {
                                                '& .title': {
                                                    fontSize: Font.Normal,
                                                    fontWeight: 'bold',
                                                    marginBottom: '10px',
                                                    color: Color.black
                                                },
                                                '& .text': {
                                                    fontWeight: 100,
                                                    fontSize: Font.Mini
                                                },
                                                '@media screen and (max-width: 570px)': {
                                                    width: '100%'
                                                }
                                            }
                                        },
                                        '& .contlogo': {
                                            $nest: {
                                                '@media screen and (max-width: 570px)': {
                                                    display: 'none'
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        '& .companyLogos': {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(6, 1fr)',
                            columnGap: '30px',
                            $nest: {
                                '& .logo': {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '40px 0px',
                                    $nest: {
                                        '& img': {
                                            maxWidth: '100%'
                                        }
                                    }
                                },
                                '@media screen and (max-width: 768px)': {
                                    gridTemplateColumns: 'repeat(4, 1fr)'
                                },
                                '@media screen and (max-width: 570px)': {
                                    gridTemplateColumns: 'repeat(2, 1fr)'
                                }
                            }
                        }
                    }
                },
                '@media screen and (max-width: 768px)': {
                    order: 1
                }
            }
        }
    }
})
