/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
// componet
import Banner from '../utils/bannerCase'
import Galery from '../utils/galery'
// context
import { UniRosarioContext } from '../../context/uniRosario'
// style
import { uniRosarioStyle } from '../../style/uniRosario/style'
const UniRosario = () => {
    const { GaleryList } = useContext(UniRosarioContext)
    const { t } = useTranslation()
    return(
        <div className={uniRosarioStyle} >
            <Banner 
                className='banner'
                fondo='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Universidad%20del%20Rosario/herobanner-caso-universidad-rosario.png'
                text= {t('rosario.banner')}
                logo='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Universidad%20del%20Rosario/alun-logo-universidad-rosario.png'
                img = 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Universidad%20del%20Rosario/iconoRosario.png'
            />
            <section className='contDescrip'>
                <div className='block'>
                    <div className='contPhoto'>
                        <img className='a' src='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Universidad%20del%20Rosario/alun-caso-universidad-rosario4.png' />
                    </div>
                    <div className='contInfo'>
                        <div className='info'>
                            <div className='title'>
                                {t('rosario.contInfo.challengeTitle')}
                            </div>
                            <div className='text'>
                            {t('rosario.contInfo.challengeDescription')}
                            </div>
                            <div className='title'> {t('rosario.contInfo.solutionTitle')}</div>
                            <div className='text'>{parse(t('rosario.contInfo.solutionDescription'))}</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='contSob' >
                <div className='sod' >
                    <div className='text'>{t('rosario.contSob.title')}</div>
                    <ul>
                        <li>{t('rosario.contSob.item1')}</li>
                        <li>{t('rosario.contSob.item2')}</li>
                        <li>{t('rosario.contSob.item3')}</li>
                        <li>{t('rosario.contSob.item4')}</li>
                        <li>{t('rosario.contSob.item5')}</li>
                    </ul>
                </div>
            </section>
            <section className='contGalery'>
                <div className='cont'>
                    <Galery list={GaleryList} />
                </div>
            </section>
        </div>
    )
}

export default UniRosario