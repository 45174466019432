import { style } from "typestyle"

import { Font, Color } from "../../var"

export const bannerStyle = style({
    width: '100%',
    padding: '150px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'AvenirNext',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    $nest: {
        '& .cont': {
            width: 'calc(100% - 40px)',
            padding: '20px',
            maxWidth: '1024px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            $nest: {
                '& .info': {
                    maxWidth: '971px',
                    marginRight: '70px',
                    $nest: {
                        '& .logo': {
                            width: '270px',
                            marginBottom: '70px',
                            padding: ' 0px 20px'
                        },
                        '& .title': {
                            fontSize: Font.MegaBig,
                            fontWeight: 'bold',
                            color: Color.white,
                            $nest: {
                                '@media screen and (max-width: 930px)': {
                                    padding: '0px 20px'
                                },
                                '@media screen and (max-width: 500px)': {
                                    fontSize: Font.Grande
                                }
                            }
                        },
                        '@media screen and (max-width: 930px)': {
                            marginRight: '0px',
                        }
                    }
                },
                '& .image' : {
                    alignSelf: 'flex-end',
                    '@media screen and (max-width: 890px)': {
                        alignSelf: 'center',
                        marginTop: '40px'
                    },
                    '@media screen and (max-width: 600px)': {
                        alignSelf: 'flex-start',
                        marginLeft: '20px',
                    }
                },
                '@media screen and (max-width: 930px)': {
                    width: '100%',
                    padding: '20px 0px',
                    flexDirection: 'column',
                },
                '@media screen and (max-width: 600px)': {
                    $nest: {
                        '& img': {
                            marginTop: '20px',
                            width: '100%'
                        }
                    }
                }
            }
        },
        '& .git': {
            marginTop: '120px',
            width: '30px'
        }
    }
})