export const goClient = (name,email,phone,company,message) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({name,email,phone,company,message,type:'Contact'});

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch("https://contact-alun.herokuapp.com/contact", requestOptions).then(response => response.json())
}

export const goTalen = (name,email,phone,area,message,file) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({name,email,phone,area,message,cv:{name:`CV de ${name}`,document: file },"type":"Talent"});

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch("https://contact-alun.herokuapp.com/contact", requestOptions).then(response => response.json());
}

export const goProject = (data) =>{
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify(data);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  return fetch("https://contact-alun.herokuapp.com/contact/home", requestOptions).then(response => response.json())
}