import { style } from "typestyle"


export const galeryStyle = style({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr )',
    $nest: {
        '& a': {
           display: 'grid' 
        },
        '& img': {
            width: '100%',
            height: 'intrinsic'
        },
        '@media screen and (max-width: 600px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
            ':nth-child(3)': {
                order: '4'
            },
            ':nth-child(6)': {
              order: '5'
            }

        }
    }
})