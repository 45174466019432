/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
// componet
import Banner from '../utils/banner'
import Galery from '../utils/galery'
// context
import { GaleryContext } from '../../context/galery'
// style
import { Color } from '../../style/var'
import { marcaStyle } from '../../style/marca/style'

const Marca = () => {
    const { GaleryList } = useContext(GaleryContext)
    const { t } = useTranslation()
    return(
        <div className={marcaStyle} >
            <Banner 
                className='banner'
                text1={t('brand.banner.text1')}
                textColor={t('brand.banner.textColor')}
                text2={t('brand.banner.text2')}
                color={Color.orange}
                parafo={t('brand.banner.paragraph')}
            />
            <section className='contDescrip'>
                <div className='cont'>
                    <div className='des'>{t('brand.contDescrip.des')}</div>
                </div>
                <div className='block'>
                    <div className='contPhoto'>
                        <img className='a' src='https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Marca/alun-matrix-international-marca.png' />
                        <img className='b' src='https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Marca/alun-saenzfety-marca.png' />
                    </div>
                    <div className='contInfo'>
                        <div className='info'>
                            <div className='title'>{t('brand.contDescrip.info.title')}</div>
                            <div className='text'>{t('brand.contDescrip.info.text')}</div>
                            <div className='title'>{t('brand.contDescrip.info.consultancyTitle')}</div>
                            <div className='text'>{t('brand.contDescrip.info.consultancyText')}</div>
                            <div className='title'>{t('brand.contDescrip.info.eventsTitle')}</div>
                            <div className='text'>{t('brand.contDescrip.info.eventsText')}</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='contSob' >
                <div className='sod' >
                    <div className='text'>
                        {t('brand.contDescrip.contSob.text')}
                    </div>
                    <img src='https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Marca/Iconos_Copa2.svg' />
                </div>
            </section>
            <Galery list={GaleryList} />
        </div>
    )
}

export default Marca