/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

// component
import Banner from '../utils/banner'
import Modal from '../modal'
// context
import { HomeContext } from '../../context/home'
// style
import { Btn, BtnTwotext, Color } from '../../style/var'
import { homeStyle } from '../../style/home/style'
import { attributesToProps } from 'html-react-parser'

const Home = () => {
    const { t } = useTranslation()
    const {
        TaskFluor,
        ProyectList,
        BrandItems,
        Team,
        CompanysInfo,
        LogoCompany,
        setViewModal
    } = useContext(HomeContext)


    return (
        <div className={homeStyle}>
            <Banner
                color={Color.orange}
                text1={`${t('home.banner.title.text1')} <br/>`}
                textColor={t('home.banner.title.textColor')}
                text2={`, <br/> ${t('home.banner.title.text2')}`}
                parafo={t('home.banner.parafo')}
                img='https://storage.googleapis.com/storage-alunideas/alunWeb/home/Img%20hero%20banner.png'
                imgRes='https://storage.googleapis.com/storage-alunideas/alunWeb/home/alun-hero-banner_movil.png'
            />
            <section className='task'>
                <div className='cont'>
                    <div className='info'>
                        <div className='phrase'>{t('home.task.phrase')}</div>
                        <div className='subtitle'>{t('home.task.subtitle')}</div>
                        <div className='paragraph'>{t('home.task.paragraph')}</div>
                        <div className='button'>
                            <Link to='/contactenos' style={{textDecoration: 'none'}} onClick={() => window.scrollTo(0, 656)}>
                                <button className={`btn ${Btn} ${BtnTwotext}`}>
                                    <span className="initialText">{t('home.task.btn')}</span>
                                    <span className="textHover">{t('home.task.btnHover')}</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className='fluor'>
                        {TaskFluor.map((item, i) => <>
                            {i !== 0 && <div key={`line-${i}`} className={`contLine ${i % 2 === 0 && 'router'}`} ><div className='line' /></div>}
                            <div key={`circul-${i}`} className='circul' >{t(`home.task.items.${item.label}`)}</div>
                        </>)}
                    </div>
                    <div className='title'>
                        {t('home.task.title.text')} <br className="jump"/> {t('home.task.title.text2')}
                    </div>
                </div>
            </section>
            <section className='proyect'>
                <div className='cont'>
                    <div className='title'>
                        {t('home.projects.phrase')}
                    </div>
                    <div className='proyects'>
                        {ProyectList.map((item, i) => <Link key={i} to={item.to} className='contImg' onClick={() => window.scrollTo(0, 0,)} ><img src={item.img} /></Link>)}
                    </div>
                    <div className='contBtn'>
                        <div className="info">
                            {t('home.projects.text')}
                        </div>
                        <div className='button'>
                            <button
                                onClick={()=> setViewModal(true)}
                                className={`btn ${Btn} ${BtnTwotext}`}>
                                <span className="initialText">{t('home.projects.btn')}</span>
                                <span className="textHover">{t('home.projects.btnHoverForm')}</span>
                            </button>
                        </div>
                        <Modal/>
                    </div>
                </div>
            </section>
            <section className='agency'>
                <div className='cont'>
                     <div className='phrase'>
                        {t('home.agency.phrase')}
                    </div>
                    <div className='title'>
                        {t('home.agency.title')}
                    </div>
                    {BrandItems.map((item, i) => <div key={i} className='brand'>
                        <div className='info'>
                            <Link to={item.to} onClick={() => window.scrollTo(0, 0,)}><div className='title'>{t(item.title)}</div></Link>
                            <div className='paragraph'>{t(item.paragraph)}</div>
                        </div>
                        <div className='line'></div>
                        <div className='icon'>
                            <Link to={item.to} onClick={() => window.scrollTo(0, 0,)}><img src={item.icon} /></Link>
                        </div>
                    </div>)}
                </div>
            </section>
            <section className='team'>
                {/* <div className='contTitle'>
                    <div className='cont'>
                        <div className='subtitle'>{t('home.team.subtitle')}</div>
                        <div className='title'>{t('home.team.title')}</div>
                    </div>
                </div>
                <div className='contPhone'>
                    {Team.map((team, i) =>
                    <div className='phone' key={i} >
                        { <div className="description" >
                            <img src={team.icon} width="50px" />
                            <div className="text">
                                {team.info}
                            </div>
                            <a className="linkedin" href={team.link} target="_blank">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>

                        </div> }
                        <img className='img' src={team.img} />
                    </div>)}
                </div> */}
                <div className='contSocial'>
                    <div className='cont'>
                        <a className="red" href="https://www.instagram.com/alunideas" target="_blank">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a className="red" href="https://twitter.com/alunideas" target="_blank">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a className="red" href="https://www.facebook.com/alunideas" target="_blank">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a className="red" href="https://www.linkedin.com/company/alun-ideas" target="_blank">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                    </div>
                </div>
            </section>
            <section className='companys'>
                <div className='cont'>
                    <div className='contTitle'>
                        <div className='subtitle'>{t('home.companys.subtitle')}</div>
                        <div className='title'>{t('home.companys.title')}</div>
                    </div>
                  {/*   <div className='company' >
                        {CompanysInfo.map((company, i) => <div key={i} className='cont'>
                            <div className='info'>
                                <Link to={company.to} ><div className='title'>{t(company.title)}</div></Link>
                                <div className='text'>{t(company.des)}</div>
                            </div>
                            <div className='contlogo'>
                                <Link to={company.to} onClick={() => window.scrollTo(0, 0,)}>
                                    <img src={company.img} />
                                </Link>
                            </div>
                        </div>)}
                    </div> */}
                    <div className='companyLogos' >
                        {LogoCompany.map((logo, i) => < Link to={logo.to} key={i} className='logo'> <img src={logo.img}  onClick={() => window.scrollTo(0, 0,)}/> </Link>)}
                    </div>
                </div>
            </section>
        </div >
    )
}

export default Home