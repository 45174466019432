import React, {createContext, useState}  from 'react'
const BarcaAcademyContext = createContext()
const { Provider, Consumer } = BarcaAcademyContext

const BarcaAcademyProvider = ({children}) =>  {
    const [GaleryList, setGaleryList] = useState([
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/alun-caso-barca-academy1.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/alun-caso-barca-academy2.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/alun-caso-barca-academy5.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/alun-caso-barca-academy6.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/alun-caso-barca-academy4.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/alun-caso-barca-academy3.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/alun-caso-barca-academy8.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/alun-caso-barca-academy7.png'},
    ])
    return(
        <Provider
            value={{
                GaleryList, setGaleryList
            }}
        >
           {children} 
        </Provider>
    )
}

export { BarcaAcademyProvider, Consumer as BarcaAcademyConsumer, BarcaAcademyContext }