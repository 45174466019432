import React, {createContext, useState}  from 'react'
const CopaTayronaContext = createContext()
const { Provider, Consumer } = CopaTayronaContext

const CopaTayronaProvider = ({children}) =>  {
    const [GaleryList, setGaleryList] = useState([
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Copa%20Tayrona/Grupo%202653.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Copa%20Tayrona/Grupo%202654.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Copa%20Tayrona/Grupo%202655.png'},
    ])
    return(
        <Provider
            value={{
                GaleryList, setGaleryList
            }}
        >
           {children} 
        </Provider>
    )
}

export { CopaTayronaProvider, Consumer as CopaTayronaConsumer, CopaTayronaContext }