/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
// componet
import Banner from '../utils/bannerCase'
import Galery from '../utils/galery'
// context
import { BarcaAcademyContext } from '../../context/barcaacademy'
// style
import { barcaAcademyStyle } from '../../style/barcaAcademy/style'
const BarcaAcademy = () => {
    const { GaleryList } = useContext(BarcaAcademyContext)
    const { t } = useTranslation()
    return(
        <div className={barcaAcademyStyle} >
            <Banner 
                className='banner'
                fondo='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/herobanner-caso-barca-academy.png'
                text={t('barca.banner')}
                logo='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/Grupo%202662.png'
            />
            <section className='contInfo'>
                <div className='cont'>
                    <div className='info'>
                        <div className='title'>{t('barca.contInfo.challengeTitle')}</div>
                        <div className='text'>
                            {t('barca.contInfo.challengeDescription')}
                        </div>
                        <div className='title'>{t('barca.requirements.title')}</div>
                        <ul className='list'>
                            <li> {t('barca.requirements.item1')}</li>
                            <li> {t('barca.requirements.item2')}</li>
                            <li> {t('barca.requirements.item3')}</li>
                            <li> {t('barca.requirements.item4')}</li>
                            <li> {t('barca.requirements.item5')}</li>
                        </ul>
                        <div className='title'>{t('barca.strategy.title')}</div>
                        <ul className='list'>
                            <li>{t('barca.strategy.item1')}</li>
                            <li>{t('barca.strategy.item2')}</li>
                            <li>{t('barca.strategy.item3')}</li>
                        </ul>
                    </div>
                    <img src='https://storage.googleapis.com/storage-alunideas/alunWeb/Caso%20Barc%CC%A7a%20Academy/alun-caso-barca-academy9.png' />
                </div>
            </section>
            <section className='contGalery'>
                <div className='cont'>
                    <Galery list={GaleryList} className='galery' />
                </div>
            </section>
        </div>
    )
}

export default BarcaAcademy