import { style } from "typestyle"

import { Color, Font } from '../var'

export const barcaAcademyStyle = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'AvenirNext',
    $nest: {
        '& .banner': {
            $nest: {
               '& .cont': {
                   $nest: {
                        '& .info': {
                            maxWidth: '620px'
                        }
                   }
               } 
            }
        },
        '& .contInfo': {
            width: 'calc(100% - 40px)',
            padding: '0 20px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: Color.white,
            $nest: {
                '& .cont': {
                    width: '100%',
                    maxWidth: '1024px',
                    display: 'flex',
                    alignItems: 'center',
                    $nest: {
                        '& .info': {
                            width: '45%',
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '40px',
                            $nest: {
                                '& .title': {
                                    fontWeight: 'bold',
                                    fontSize: Font.Normal,
                                    marginBottom: '25px'
                                },
                                '& .text': {
                                    fontSize: Font.Mini,
                                    fontWeight: 100,
                                    marginBottom: '80px'
                                },
                                '& .list': {
                                    fontSize: Font.Mini,
                                    fontWeight: 100,
                                    marginBottom: '80px',
                                    paddingLeft: '15px',
                                    marginTop: '0',
                                },
                                '@media screen and (max-width: 920px)': {
                                    marginTop: '50px',
                                    width: '80%'
                                },
                                '@media screen and (max-width: 500px)': {
                                    width: 'calc(100% - 40px)',
                                    padding: '20px'
                                }
                            }
                        },
                        '@media screen and (max-width: 920px)': {
                            flexDirection: 'column'
                        },
                        '@media screen and (max-width: 500px)': {
                            $nest: {
                                '& img': {
                                    width: '80%'
                                }
                            }
                        }
                    }
                }
            }
        },
        '& .contGalery': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            $nest: {
                '& .cont':{
                    width: '100%',
                    $nest: {
                        '& .galery': {
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            $nest: {
                                '@media screen and (max-width: 500px)': {
                                    gridTemplateColumns: '1fr'
                                }
                            }
                        }
                    }
                },
                '@media screen and (max-width: 1024px)': {
                    width: '100%',
                    padding: '0px',
                }
            }
        }
    }
})