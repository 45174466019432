import { style } from "typestyle"

import { Color, Font } from '../var'

export const laboratorioStyle = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'AvenirNext',
    $nest: {
        '& .banner': {
            $nest: {
                '& .cont': {
                    $nest: {
                        '& .info': {
                            maxWidth: '720px'
                        },
                        '& .text': {
                            maxWidth: '560px'
                        }
                    }
                }
            }
        },
        '& .contImp': {
            backgroundColor: Color.orange,
            padding: '90px 20px',
            width: 'calc(100% - 40px)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            $nest: {
                '& .cont': {
                    width: '100%',
                    maxWidth: '1024px',
                    display: 'flex',
                    flexDirection: 'row',
                    $nest: {
                        '& .info': {
                            width: '60%',
                            display: 'flex',
                            flexDirection: 'column',
                            $nest: {
                                '& .title': {
                                    width: '100%',
                                    color: Color.white,
                                    fontSize: Font.MegaBig,
                                    fontWeight: 'bold',
                                    marginBottom: '30px',
                                    $nest: {
                                        '@media screen and (max-width: 768px)': {
                                            fontSize: Font.Grande
                                        }
                                    }
                                },
                                '& .des': {
                                    color: Color.white,
                                    fontSize: Font.Normal,
                                    fontWeight: 100,
                                    marginBottom: '10px',
                                    '@media screen and (max-width: 650px)': {
                                        width: '95%'
                                    }

                                },
                                '@media screen and (max-width: 650px)': {
                                    width: '100%'
                                }
                            }
                        },
                        '& img': {
                            width: '300px'
                        },
                        '@media screen and (max-width: 650px)': {
                            flexWrap: 'wrap'
                        }
                    }
                }
            }
        },
        '& .contSkill': {
            padding: '90px 20px',
            width: 'calc(100% - 40px)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            $nest: {
                '& .cont': {
                    width: '100%',
                    maxWidth: '1024px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    //display: 'grid',
                    //gridTemplateColumns: 'repeat(2, 1fr)',
                    //gridGap: '50px 160px',
                    $nest: {
                        '& .skill': {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '40%',
                            padding: '2% 5%',
                            $nest: {
                                '& img': {
                                    height: '124px'
                                },
                                '& .title': {
                                    margin: '20px 0px',
                                    fontWeight: 'bold',
                                    fontSize: Font.Normal
                                },
                                '& .des': {
                                    fontWeight: 100,
                                    fontSize: Font.Normal,
                                    textAlign: 'center',
                                    $nest: {
                                        '@media screen and (max-width: 650px)': {
                                            textAlign: 'left'
                                        }
                                    }
                                },
                                '@media screen and (max-width: 650px)': {
                                    alignItems: 'flex-start',
                                    width: '100%',
                                    padding: '0',

                                }
                            }
                        },
                        '@media screen and (max-width: 768px)': {
                            gridGap: '50px',
                        },
                        '@media screen and (max-width: 650px)': {
                            gridTemplateColumns: '1fr'
                        }
                    }
                }
            }
        }
    }
})