import React, {createContext, useState}  from 'react'
const GaleryContext = createContext()
const { Provider, Consumer } = GaleryContext

const GaleryProvider = ({children}) =>  {
    const [GaleryList, setGaleryList] = useState([
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Marca/alun-fronterasky-desarrollo-web.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Marca/alun-blossomdistrict-redes-sociales.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Marca/alun-copatayrona-marca.png', to: '/caso/copaTayrona' },
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Marca/alun-masterbeer-marca.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Marca/alun-barcaacademy-redes-sociales.png', to:'/caso/barcaAcademy'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Marca/alun-sistema-registro.png'}
    ])
    return(
        <Provider
            value={{
                GaleryList, setGaleryList
            }}
        >
           {children} 
        </Provider>
    )
}

export { GaleryProvider, Consumer as GaleryConsumer, GaleryContext }