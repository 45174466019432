import React, {useContext} from 'react'
import { useTranslation } from 'react-i18next'
//context
import {HomeContext} from '../../context/home'
//Style
import {modalStyle} from '../../style/modal/style'
import {Input,Btn} from '../../style/var'
//Component
import {validateGlobal,toastMessage} from '../utils/validateGlobal'
//API
import { goProject } from '../../api/contactenos'

const Modal = () =>{
	const { t } = useTranslation()
	const goDownload = () => {
		const[validate] = validateGlobal(
			{
				Name : 'Require|String',
				Email: 'Require|Email',
			},
			{
				Name,
				Email,
			},
			{
				message:
				{
					Name: 'El nombre es requerido',
					Email: 'El mail no es valido',
				},
				position: 'bottom-right',
				correctValidate: false
			}
		)
		if(validate) {
			const data= {
				"name": Name, "email": Email, "message": Message, "type": "Home"
			}
			goProject(data).then(
				() => {
					setName('')
					setEmail('')
					setMessage('')
					toastMessage({ message: t('contact.message.correctMessageHome'), position: 'bottom-right' })
					window.open('https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/Al%C3%BCn%20Ideas%20-%20The%20Storytelling%20House%202020.pdf', '_blank')
					setViewModal(null)
				}
			).catch(() => toastMessage({ message: t('contact.message.errorDefault'), position: 'bottom-right', error: true }))
		}
	}
	const {
		ViewModal, setViewModal,
		Name, setName,
		Email, setEmail,
		Message, setMessage,
	} = useContext(HomeContext)
	return ViewModal ? (
		<div className={modalStyle}>
			<div className="container">
				<button onClick={()=> setViewModal(null)} className="close">X</button>
				<div className="textModal">
					{t('modal.text')}
				</div>
				<div className="form">
					<input className={`${Input} input`} placeholder={t('contact.form2.name')} value={Name} onChange={e => setName(e.target.value)} />
					<input className={`${Input} input`} placeholder={t('contact.form2.email')} value={Email} onChange={e => setEmail(e.target.value)} />
					<input className={`${Input} input`} placeholder={t('contact.form.message2')} value={Message} onChange={e => setMessage(e.target.value)} />
					<button className={`btn ${Btn} `} disabled={!Email}   onClick={()=>goDownload()}>
						<span className="initialText">{t('contact.form.download')}</span>
					</button>
				</div>
			</div>
		</div>
	): <div/>
}

export default Modal