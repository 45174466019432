import {style} from 'typestyle'

import { Color, Font }  from '../var'

export const modalStyle = style({
    width: '100%',
    fontFamily: 'AvenirNext',
    top: '0',
    position: 'fixed',
    left: '0',
    backgroundColor:'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    $nest: {
        '& .container': {
            position: 'relative',
            top: '10rem',
            backgroundColor: Color.white,
            width:'100%',
            maxWidth: '1024px',
            display: 'flex',
            flexDirection: 'row',
            padding: '60px',
            boxSizing:'border-box',
            $nest:{
                '& .close': {
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    border: '0',
                    backgroundColor:Color.black,
                    padding: '10px 16px',
                    margin: '10px',
                    borderRadius: '50%',
                    color: Color.white,
                    fontSize: '20px',
                    $nest: {
                        '&:focus':{
                            outline: 'none',
                        }
                    }
                },
                '& .textModal': {
                    width: '50%',
                    fontSize: Font.MegaBig,
                    display: 'flex',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    fontWeight: 'bold',
                    color: Color.orange,
                    padding: '0 15px',
                },
                '& .form': {
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    $nest:{
                        '& .btn': {
                            marginTop: '100px'
                        },
                        '& input':{
                            $nest: {
                                '&:focus':{
                                    outline: 'none',
                                    borderColor: Color.darkGrey
                                }
                            }
                        }
                    }
                },
                '@media screen and (max-width: 600px)': {
                    flexDirection:'column',
                    top:'5rem',
                    padding: '10px',
                    zIndex:'100',
                    $nest: {
                        '& .textModal': {
                            width:'90%',
                            fontSize: Font.Title,
                            padding: '35px 35px',
                        },
                        '& .form': {
                            width: '90%',
                            $nest:{
                                '& .btn': {
                                    marginTop: '50px'
                                }
                            }
                        }
                    }
                }
            }
        },
    }
})