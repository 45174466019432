/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// componet
import Menu from './menu'
import Router from './router'
import Footer from './footer'
const Aplication = () => {
  return (
    <div >
      <Menu />
      <Router />
      <Footer />
    </div>
  );
}

export default Aplication;
