import { style } from "typestyle"

import { Color, Font } from '../var'

export const innovacionStyle = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'AvenirNext',
    $nest: {
        '& .banner': {
            $nest: {
                '& .cont': {
                    $nest: {
                        '& .info': {
                            maxWidth: '600px'
                        },
                        '& .text': {
                            maxWidth: '540px'
                        }
                    }
                }
            }
        },
        '& .contImp': {
            backgroundColor: Color.white,
            padding: '90px 20px',
            width: 'calc(100% - 40px)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            $nest: {
                '& .cont': {
                    width: '100%',
                    maxWidth: '1024px',
                    display: 'flex',
                    flexDirection: 'row',
                    $nest: {
                        '& .info': {
                            width: '85%',
                            display: 'flex',
                            flexDirection: 'column',
                            $nest: {
                                '& .title': {
                                    width: '100%',
                                    color: Color.orange,
                                    fontSize: Font.MegaBig,
                                    fontWeight: 'bold',
                                    marginTop: '100px',
                                    $nest: {
                                        '@media screen and (max-width: 768px)': {
                                            fontSize: Font.Grande
                                        },
                                        '@media screen and (max-width: 450px)': {
                                            width: '98%',
                                        }

                                    }
                                },
                                '@media screen and (max-width: 768px)': {
                                    width: '100%',
                                    fontSize: Font.Grande,
                                }
                            }
                        },
                    }
                }
            }
        },
        '& .contSkill': {
            padding: '90px 20px',
            width: 'calc(100% - 40px)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: Color.lightOrange,
            $nest: {
                '& .cont': {
                    width: '100%',
                    maxWidth: '1024px',
                    display: 'grid',
                    gridTemplateColumns: '60% 40%',
                    gridGap: '50px',
                    $nest: {
                        '& .info': {
                            $nest: {
                                '& .skill': {
                                    display: 'grid',
                                    gridTemplateColumns: '3fr 1fr',
                                    gridGap: '60px',
                                    $nest: {
                                        '& img': {
                                            height: '130px'
                                        },
                                        '& .contDes': {
                                            $nest: {
                                                '& .title': {
                                                    margin: '20px 0px',
                                                    fontWeight: 'bold',
                                                    fontSize: Font.Normal,
                                                },
                                                '& .des': {
                                                    fontWeight: 100,
                                                    fontSize: Font.Normal,
                                                },
                                                '@media screen and (max-width: 550px)': {
                                                    order: 2
                                                }
                                            }
                                        },
                                        '@media screen and (max-width: 550px)': {
                                            gridTemplateColumns: '1fr',
                                            gridGap: '5px',
                                            marginBottom: '50px'
                                        }
                                    }
                                },
                            }
                        },
                        '& .like': {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            $nest: {
                                '& img': {
                                    width: '270px'
                                },
                                '@media screen and (max-width: 550px)': {
                                    justifyContent: 'flex-start'
                                }
                            }
                        },
                        '@media screen and (max-width: 768px)': {
                            gridTemplateColumns: '1fr'
                        }
                    }
                }
            }
        },
        '& .blockWhile': {
            width: '100%',
            padding: '30px 0px',
            backgroundColor: Color.white
        }
    }
})