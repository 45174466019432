import { style } from "typestyle"

import { Color, Font } from '../var'

export const copaTayronaStyle = style({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'AvenirNext',
    $nest: {
        '& .banner': {
            $nest: {
               '& .cont': {
                   $nest: {
                        '& .info': {
                            maxWidth: '620px'
                        }
                   }
               } 
            }
        },
        '& .contDescrip': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: `linear-gradient(90deg, ${Color.linhtGreen} 0%, ${Color.linhtGreen} 50%, ${Color.green} 50%, ${Color.green} 100%)`,
            padding: '0px',
            $nest: {
                '& .contInfo': {
                    width: 'calc(100% - 40px)',
                    maxWidth: '1024px',
                    padding: '20px',
                    display: 'flex',
                    $nest: {
                       '& .info': {
                           width: '100%',
                           display: 'flex',
                           flexDirection: 'row',
                           justifyContent: 'space-between',
                           $nest: {
                                '& .cont':{
                                    width: '45%',
                                    padding: '100px 0px', 
                                    $nest: {
                                        '&.a': {
                                            backgroundColor: Color.linhtGreen,
                                        },
                                        '&.b': {
                                            backgroundColor: Color.green,
                                            color: Color.white
                                        },
                                        '& .title': {
                                            fontSize: Font.Normal,
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        },
                                        '& .text': {
                                            fontSize: Font.Mini,
                                            fontWeight: 100,
                                            marginBottom: '30px'
                                        },
                                        '@media screen and (max-width: 600px)': {
                                            width: 'calc(100% - 40px)',
                                            padding: '100px 20px', 
                                        }
                                    }
                                },
                                '@media screen and (max-width: 600px)': {
                                    flexDirection: 'column',
                                }
                            }
                        },
                        '@media screen and (max-width: 600px)': {
                            width: '100%',
                            padding: '0px',
                        }
                    }
                },
                   '@media screen and (max-width: 940px)': {
                            flexWrap: 'wrap'
                }

            }
        },
        '& .contChange': {
            display: 'flex',
            width: 'calc(100% - 40px)',
            padding: '50px 20px',
            justifyContent: 'center',
            backgroundColor: Color.lifeGreen,
            $nest: {
                '& .change': {
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    maxWidth: '1024px',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    $nest: {
                        '& .cont': {
                            color: Color.black,
                            fontSize: Font.Mini,
                            width: '35%',
                            fontWeight: 100,
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            $nest: {
                                '& img': {
                                    width: '100%'
                                },
                                '& .text': {
                                    marginTop: '30px'
                                },
                                '@media screen and (max-width: 500px)': {
                                    marginBottom: '50px',
                                    width: '60%'
                                }
                            }
                        },
                        '& .icon': {
                            fontSize: Font.Title,
                            color: Color.linhtGreen,
                            $nest: {
                                '@media screen and (max-width: 500px)': {
                                    display: 'none'
                                }
                            }
                        },
                        '@media screen and (max-width: 500px)': {
                            flexDirection: 'column',
                        }
                    }
                }
            }
        },
        '& .contGalery': {
            width: 'calc(100% - 40px)',
            padding: '0px 20px',
            display: 'flex',
            justifyContent: 'center',
            $nest: {
                '& .cont':{
                    width: '100%',
                    maxWidth: '1024px',
                    $nest: {
                        '& .galery': {
                            $nest: {
                                '@media screen and (max-width: 500px)': {
                                    gridTemplateColumns: '1fr'
                                }
                            }
                        }
                    }
                },
                '@media screen and (max-width: 1024px)': {
                    width: '100%',
                    padding: '0px',
                }
            }
        },
        '& .contElement': {
            width: 'calc(100% - 40px)',
            backgroundColor: Color.white,
            padding: '80px 20px',
            display: 'flex',
            justifyContent: 'center',
            $nest: {
                '& .cont': {
                    width: '100%',
                    maxWidth: '1024px',
                    display: 'flex',
                    flexDirection: 'column',
                    $nest: {
                        '& .title': {
                            padding: '20px 0px',
                            color: Color.orange,
                            fontWeight: 'bold',
                            fontSize: Font.Mediun,
                            width: '49%',
                            $nest: {
                                '@media screen and (max-width: 500px)': {
                                    width: 'calc(100% - 40px)',
                                    padding: '20px'
                                }
                            }
                        },
                        '& .contDes': {
                            display: 'flex',
                            width: '100%',
                            alignItems: 'stretch',
                            marginTop: '30px',
                            justifyContent: 'space-between',
                            $nest: {
                                '& .des': {
                                    width: '40%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    $nest: {
                                        '& .group':{
                                            marginTop: '20px',
                                            $nest: {
                                                '& .title': {
                                                    padding: '0px',
                                                    color: Color.black,
                                                    fontWeight: 'bold',
                                                    fontSize: Font.Normal,
                                                    width: '100%',
                                                },
                                                '& .text': {
                                                    fontSize: Font.Normal,
                                                    fontWeight: 100,
                                                    $nest: {
                                                        '@media screen and (max-width: 600px)':{
                                                            paddingTop: '10px'
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        '@media screen and (max-width: 830px)': {
                                            width: '100%',
                                            marginBottom: '40px'
                                        },
                                        '@media screen and (max-width: 500px)': {
                                            width: 'calc(100% - 40px)',
                                            padding: '0 20px'
                                        }
                                    }
                                },
                                '& img': {
                                    $nest: {
                                        '@media screen and (max-width: 500px)': {
                                            width: '100%'
                                        }
                                    }
                                },
                                '@media screen and (max-width: 830px)': {
                                    flexWrap: 'wrap',
                                    justifyContent: 'center'
                                },
                            }
                        }
                    }
                },
                '@media screen and (max-width: 500px)': {
                    width: '100%',
                    padding: '60px 0'
                }
            }
        }
    }
})