/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import parse from 'html-react-parser'
// style
import { bannerStyle } from '../../../style/utils/bannerCase/style'

const BannerCase = ({logo, text, img, className, fondo }) => {
    return (
        <div className={`${bannerStyle} ${className}`} style={{backgroundImage: `url(${fondo})` }} >
            <div className='cont' >
                <div className='info' >
                    {logo && <img className='logo' src={logo} />}
                    <div className='title' >
                        {parse(text)}
                    </div>
                </div>
                {img && <div className='image'>
                    <img src={img} />
                </div>}
            </div>
            <img className='git' src='https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/alun-scroll-down-casos-2.gif' />
        </div>
    )
}

export default BannerCase