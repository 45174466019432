/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { fooStyle } from '../../style/footer/index'

const Footer = () => {
    const { t } = useTranslation()

    return (
        <div className={fooStyle}>
            <div className='info' >
                <div className='item'>
                    <div className='titulos'>{t('footer.contactenos.title')}</div>
                    <a className='text' href="tel:+5717505456">+57 (1) 750 54 56</a>
                    <a className='text' href="mailto:ventas@alunideas.com">ventas@alunideas.com</a>
                    <a className='text' href="https://www.google.es/maps/place/alun+ideas/@4.6966988,-74.0381806,18z/data=!4m5!3m4!1s0x8e3f9a8d2bfc7697:0xe4dd691747c06d7!8m2!3d4.6971768!4d-74.0369345" target="_blank">Carrera 11a #118-42, Santa Bárbara</a>
                    <a className='text' href="https://www.google.es/maps/place/alun+ideas/@4.6966988,-74.0381806,18z/data=!4m5!3m4!1s0x8e3f9a8d2bfc7697:0xe4dd691747c06d7!8m2!3d4.6971768!4d-74.0369345">Bogotá D.C, Colombia</a>
                </div>
                <div className='item'>
                    <div className='titulos'>{t('footer.descubranos.title')}</div>
                    <Link to="/contactenos" onClick={() => window.scrollTo(0, 656)}>    
                        <div className='text' >{t('footer.descubranos.text')}</div>
                    </Link>
                    <Link to="/contactenos" onClick={() => window.scrollTo(0, 1899)}>
                        <div className='text' >{t('footer.descubranos.text2')}</div>
                    </Link>
                </div>
                <div className='item privacity'>
                    <div className='titulos'>{t('footer.partners.title')}</div>
                    {/* <a href="https://infinito.group/"  target="_blank" className='text' > {t('footer.partners.infinito')}</a> */}
                    <a href ="https://yayoamortegui.com/" target="_blank" className='text' >yayoamortegui.com</a>
                    {/* <a href = "http://bogotacb.com" target="_blank" className='text' >{t('footer.partners.greater')}</a> */}
                </div>
                <div className='item center'>
                    <Link className="logo" to='/' >
                        <img src="https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/alun-logo-black.png"  alt="LogoAlun"/>
                    </Link>
                    <div className='text' >

                        <a href="https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/avisoprivacidad.pdf" target="_blank">
                            {t('footer.aviso')}
                        </a>
                        <a href="https://storage.googleapis.com/storage-alunideas/alunWeb/Generales/politicadatos.pdf" target="_blank">
                        {t('footer.politicas')}
                        </a>
                        </div>
                </div>
            </div>
            <div className='info right text' >©2020 Alün Ideas</div>
            <a href='https://alunideas.com/' target="_blank" className='contMade'>
                        {t('footer.madeAlun')}
                    </a>
        </div>
    )
}
export default Footer