import React, {createContext, useState}  from 'react'
import { useAnimate } from 'react-simple-animate'
const MenuContext = createContext()
const { Provider, Consumer } = MenuContext

const MenuProvider = ({children}) =>  {
    const [MenuItems, setMenuItems] = useState([
        {label: 'marca', link: '/marca'},
        {label: 'laboratorio', link: '/laboratorio' },
        {label: 'innovacion', link: '/innovacion' },
        {label: 'contactenos', link: '/contactenos' },
        /* {label: 'infinito', link: '/infinito' }, */
    ])
    const [ViewMenu, setViewMenu] = useState(false)
    const { play: menuTooglePlay, style: menuToogleStyle } = useAnimate({
        start: { bottom: '300vh' },
        end: { bottom: '0px' },
        duration: 0
    })
    const [open, setOpen] = useState(false);
    return(
        <Provider
            value={{
                MenuItems, setMenuItems,
                ViewMenu, setViewMenu,
                menuToogleStyle, menuTooglePlay,
                open, setOpen
            }}
        >
           {children} 
        </Provider>
    )
}

export { MenuProvider, Consumer as MenuConsumer, MenuContext }