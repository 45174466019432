import React, {createContext, useState}  from 'react'
const LaboratorioContext = createContext()
const { Provider, Consumer } = LaboratorioContext

const LaboratorioProvider = ({children}) =>  {
    const [GaleryList, setGaleryList] = useState([
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/alun-masterbeer-redes-sociales.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/alun-ucep-desarrollo-videojuegos.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/alun-infinito-desarrollo-web.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/alun-blossomdistrict-marca.png'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/alun-barcaacademy-redes-sociales.png', to:'/caso/barcaAcademy'},
        {img: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/alun-masterbeer-marca.png'}
    ])
    const [SkillList, setSkillList] = useState([
        {icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Iconos/Iconos_Social%20Media2.svg', 
        label: 'laboratory.skills.social.label', 
        des: 'laboratory.skills.social.description'},
        {icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/ico%20marketing%20digital.png', 
        label: 'laboratory.skills.marketing.label', 
        des: 'laboratory.skills.marketing.description'},
        {icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Seccio%CC%81n%20Laboratorio%20Digital/Iconos_SEO-SEM2.svg', 
        label:  'laboratory.skills.seo.label', 
        des: 'laboratory.skills.seo.description'},
        {icon: 'https://storage.googleapis.com/storage-alunideas/alunWeb/Iconos/Iconos_In-Outbound2.svg', 
        label: 'laboratory.skills.inBound.label', 
        des: 'laboratory.skills.inBound.description'},
    ])
    return(
        <Provider
            value={{
                GaleryList, setGaleryList,
                SkillList, setSkillList
            }}
        >
           {children} 
        </Provider>
    )
}

export { LaboratorioProvider, Consumer as LaboratorioConsumer, LaboratorioContext }